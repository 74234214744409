export const shareVia = (message) =>{
    const shareData = {
        type : "SocialShare",
        message: message,
    }
    try {
        window.sendMessageToVuplex("SocialShare", shareData.message);
        // 'MDN shared successfully'
    } catch (err) {
        console.error('Error: ' + err)
        // 'Error: ' + err
    }
}