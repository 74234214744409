import React, { useContext, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { GlobalContext } from "./App";
import { callGetApi } from "./api/ApiCaller";
import * as ApiConst from "./api/ApiConst";
import { NO_ERROR } from "./api/constants";
import Dashboard from "./components/Dashboard";
import RegisterLogin from "./components/RegisterLogin";
import Account from "./components/account/Account";
import Wallet from "./components/wallet/Wallet";
import { LOGOUT, SET_PROFILE_DETAILS } from "./reducer/actions/userActions";
import { SET_WALLET_DETAILS } from "./reducer/actions/utilityActions";
import AddCash from "components/wallet/AddCash";
import PaymentMethod from "components/wallet/PaymentMethod";
import Withdraw from "components/wallet/Withdraw";
import History from "components/wallet/History";
import Setting from "components/Setting";
import Kyc from "components/account/kyc/Kyc";
import { KycNew } from "components/account/kyc/KycNew";
import { UploadCard } from "components/account/kyc/UploadCard";
import { Profile } from "components/Profile";

const AppRoutes = () => {
    const navigate = useNavigate();
    const { authState, dispatchAuth, dispatchUtility, utilityState } = useContext(GlobalContext);

    const lastValue = parseInt(document.getElementById("refreshPageAfterLogout").value);
    let call = false;
    useEffect(() => {
        const timer = setInterval(() => {
            if (parseInt(document.getElementById("refreshPageAfterLogout").value) > lastValue && !call) {
                call = true;
                dispatchAuth({ type: LOGOUT });
                window.sendMessageToVuplex("Refresh", {});
            }
        }, 200);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (authState.isAuthenticated) {
            getWalletDetails();
        }
    }, [authState.isAuthenticated]);

    const getWalletDetails = () => {
        callGetApi(ApiConst.Wallet_Balance_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                dispatchUtility({
                    type: SET_WALLET_DETAILS,
                    payload: response.responsePacket,
                });
            } else {
                toast.error(response.message, {
                    id: "getWalletDetails",
                });
            }
        });
    };

    window._getWalletDetails = () => {
        getWalletDetails();
        window._getMyRunningGameList();
    };

    useEffect(() => {
        if (!authState.isAuthenticated || !authState.userDetails) {
            navigate("/");
        }
        if (authState.isAuthenticated) {
            callGetApi(ApiConst.Profile_Detail_GET, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    callVuPlex(response.responsePacket);
                    dispatchAuth({
                        type: SET_PROFILE_DETAILS,
                        payload: response.responsePacket,
                    });
                } else {
                    toast.error(response.message, {
                        id: "getProfileDetails",
                    });
                }
            });
        }
    }, [authState.isAuthenticated, utilityState]);

    const callVuPlex = (userDetailsTemp) => {
        const hash = btoa(userDetailsTemp.countryCode + ":" + userDetailsTemp.mobileNumber + ":" + userDetailsTemp.secretKey);
        const authToken = "Basic " + hash;
        userDetailsTemp.authToken = {
            accessToken: authToken,
        };
        window.sendMessageToVuplex("UpdateObject", userDetailsTemp);
    };

    useEffect(() => {
        if (!authState.isAuthenticated || !authState.userDetails) {
            navigate("/");
        }
        if (authState.isAuthenticated) {
            callGetApi(ApiConst.Wallet_Balance_GET, {}, (response) => {
                if (response.errorCode === NO_ERROR) {
                    dispatchUtility({
                        type: SET_WALLET_DETAILS,
                        payload: response.responsePacket,
                    });
                } else {
                    toast.error(response.message, {
                        id: "getWalletBalance",
                    });
                }
            });
        }
    }, [utilityState.activeWalletTab]);

    window._showToast = (message, id) => {
        toast.error(message, { id: id });
    };

    const getRoutes = () => {
        if (!authState.isAuthenticated || !authState.userDetails) {
            return <Route path="*" element={<RegisterLogin />} />;
        } else {
            return (
                <>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/wallet" element={<Wallet />} />
                    <Route path="/add-cash" element={<AddCash />} />
                    <Route path="/payment-method" element={<PaymentMethod />} />
                    <Route path="/withdraw" element={<Withdraw />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/setting" element={<Setting />} />
                    <Route path="/kyc" element={<Kyc />} />
                    <Route path="/kycNew" element={<KycNew />} />
                    <Route path="/upload-card" element={<UploadCard />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="*" element={<Navigate to={"/"} />} />
                </>
            );
        }
    };

    return <Routes>{getRoutes()}</Routes>;
};

export default AppRoutes;
