import dateUtilities from "./DateUtilities";

export const isEmpty = (val) => {
  if (!val || val === null || val === undefined || val === "" || val === 0) {
    return true;
  }
  return false;
};

export const isNumeric = (str) => {
  if (typeof str != "string") {
    return false; // we only process strings!
  }
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const getImageSrc = (imgSrc) => {
  if (this.isEmpty(imgSrc)) {
    // if imgSrc is empty then set default image.
    return "/assets/images/001/user.png";
  }
  return imgSrc;
};

export const isMobile = (mobile) => {
  const regEx = /^[6-9]\d{9}$/;
  return regEx.test(mobile);
};

export const isEmail = (email) => {
  const regEx =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regEx.test(email);
};

export const isUpiId = (upiId) => {
  const regEx = /[a-zA-Z0-9.\\-_]{2,256}@[a-zA-Z]{2,64}/;
  return regEx.test(upiId);
};

export const isPanCard = (panCard) => {
  const regEx = /[A-Z]{5}[0-9]{4}[A-Z]{1}/gm;
  return regEx.test(panCard);
};

export const getDOB = (value) => {
  if (typeof value === "number" || value.toString().split("") === 1) {
    return dateUtilities.formattedDate(value, "yyyy-MM-dd");
  }
  return value;
};

export const getTimeFromDate = (value) => {
  return new Date(value).getTime();
};

export const isValidIsdCode = (isdCode) => {
  const regEx = /^\+\d{1,3}$/;
  return regEx.test(isdCode);
};

export const isSpecialChar = (val) => {
  const specialChar = /[!@#£~$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
  return specialChar.test(val);
};

export const isValidHost = (host) => {
  const regEx =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
  return regEx.test(host);
};

export const isValidPort = (port) => {
  const regEx =
    /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi;
  return regEx.test(port);
};

export const isValidJsonString = (jsonString) => {
  try {
    JSON.parse(jsonString);
  } catch (e) {
    return false;
  }
  return true;
};

export const getAmountWithCurrencySubdivision = (currency, amount) => {
  if ((amount || amount === 0) && currency) {
    return (
      currency.currencySymbol +
      " " +
      amount.toFixed(this.calculateSubdivision(currency.unitSubdivision))
    );
  } else if (currency === undefined || currency === null || currency === "") {
    return amount;
  }
  return false;
};

export const insertSpaceInKeyDirect = (string) => {
  string = string.replace(/([A-Z])/g, ' $1').trim();
  return string;
}