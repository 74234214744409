import { AuthTokenKey, UserDetailsKey, deleteDataFromStore, setDataInStore } from "utils/DataStore";
import { LOGIN, LOGOUT, SET_PROFILE_DETAILS } from "../actions/userActions";
import { isEmpty } from "utils/TextUtils";


export const userReducer = (state, action) => {
    switch (action.type) {
        case LOGIN:
            var hash = "";
            if(!isEmpty(action.payload.mobileNumber)) {
                hash = btoa(action.payload.countryCode + ":" + action.payload.mobileNumber + ":" + action.payload.secretKey);
            } else if(!isEmpty(action.payload.email)) {
                hash = btoa(action.payload.email + ":" + action.payload.secretKey);
            }
            const authToken = "Basic " + hash;
            setDataInStore(AuthTokenKey, { accessToken: authToken });
            setDataInStore(UserDetailsKey, action.payload);
            return {
                ...state,
                authToken,
                isAuthenticated: true,
                userDetails: action.payload,
            };
        case LOGOUT:
            deleteDataFromStore(AuthTokenKey);
            deleteDataFromStore(UserDetailsKey);
            return {
                isAuthenticated: false,
                authToken: null,
                userDetails: null,
            };
        case SET_PROFILE_DETAILS:
            setDataInStore(UserDetailsKey, action.payload);
            return {
                ...state,
                userDetails: JSON.parse(JSON.stringify(action.payload)),
            };
        default:
            return state;
    }
};
