import { GlobalContext } from "App";
import { callPostApi } from "api/ApiCaller";
import { Get_KYC_Document_List_POST } from "api/ApiConst";
import Wrapper from "components/Wrapper";
import CustomHeader from "components/common/CustomHeader";
import { AADHAR_CARD, PAN_CARD } from "constants/staticConstants";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const KycNew = () => {
    const { authState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [aadhaarStatus, setAadhaarStatus] = useState("");
    const [panStatus, setPanStatus] = useState("");

    useEffect(() => {
        getStatus();
    }, [])
    
    const getStatus = () => {
        callPostApi(Get_KYC_Document_List_POST, {}, {}, (response) => {
            if (response.errorCode === 0) {
                const res = response.responsePacket;
                if(res === null || res === undefined) {
                    return;
                }
                res.map((item) => {
                    if (item.documentType === "PanCard") {
                        if (item.verificationStatus === "Approved") {
                            if (authState?.userDetails?.kycVerificationStatus) {
                                setPanStatus(authState?.userDetails?.kycVerificationStatus);
                            }
                        }
                        setPanStatus(item.verificationStatus);
                    }
                    if (item.documentType === "AadharCard") {
                        if (item.verificationStatus === "Approved") {
                            if (authState?.userDetails?.kycVerificationStatus) {
                                setAadhaarStatus(authState?.userDetails?.kycVerificationStatus);
                            }
                        } else {
                            setAadhaarStatus(item.verificationStatus);
                        }
                    }
                });
            } else {
                console.error("Catch Error :: ", response.message)
            }
        },(err) => {
            console.error("Catch Error :: ", err.message)
        })
    }

    return (
        <Wrapper>
            <CustomHeader title={"KYC"} />
            <div className="p-3">
                <div className="yellowCard mb-3">
                    <div className="innerCard py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/panCardIcon.png" alt="panCardIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600 ">PAN Card</p>
                                </div>
                                <div className={`ml-2 text-black btn-sm rounded ${panStatus === "Approved" ? "bg-success" : panStatus === "Rejected" ? "bg-danger " : panStatus === "Pending" ? "bg-orange" : ""}`}>
                                    {panStatus}
                                </div>
                            </div>
                            {(panStatus === "" || panStatus === "Rejected") && <div onClick={() => navigate("/upload-card", {state : {type: PAN_CARD}})}>
                                <button className="btnRed text-white font-size-18 font-weight-600 py-1 px-3">Verify</button>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="yellowCard mb-3">
                    <div className="innerCard py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/aadharCardIcon.png" alt="aadharCardIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Aadhaar Card</p>
                                </div>
                                <div className={`ml-2 text-black btn-sm rounded ${aadhaarStatus === "Approved" ? "bg-success" : aadhaarStatus === "Rejected" ? "bg-danger " : aadhaarStatus === "Pending" ? "bg-orange" : ""}`}>
                                    {/* Pending > Orange || Rejected > Red || Approved > Green */}
                                    {aadhaarStatus}
                                </div>
                            </div>
                            {(aadhaarStatus === "" || aadhaarStatus === "Rejected") && <div onClick={() => navigate("/upload-card", {state : {type: AADHAR_CARD}})}>
                                <button className="btnRed text-white font-size-18 font-weight-600 py-1 px-3">Verify</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
