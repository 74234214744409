import React from "react";

const Loader = () => {

    return <>
        <div className="d-flex align-items-center" style={{
            position: "absolute",
            width: "100%",
            height: "100%"
        }}>
            <div className="loader mx-auto"></div>
        </div>
    </>
};

export default Loader;
