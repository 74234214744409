import React, { useContext, useState } from "react";
import Wrapper from "./Wrapper";
import CustomHeader from "./common/CustomHeader";
import { useNavigate } from "react-router-dom";
import { LOGOUT } from "reducer/actions/userActions";
import toast from "react-hot-toast";
import { GlobalContext } from "App";
import ModalBox from "./common/Modal";
import { callGetApiWithoutAuth } from "api/ApiCaller";
import { Get_StaticPages_List_GET } from "api/ApiConst";
import { NO_ERROR } from "api/constants";

const Setting = () => {
    const navigate = useNavigate();
    const { utilityState, dispatchAuth, dispatchUtility } = useContext(GlobalContext);
    const callVuPlexLogoutEvent = () => {
        window.sendMessageToVuplex("Logout", {});
    };
    const [helpData, setHelpData] = useState("");
    const [showHelp, setShowHelp] = useState(false);

    const [aboutUsData, setAboutUsData] = useState("");
    const [showAboutUs, setShowAboutUs] = useState(false);
    const handleLogout = () => {
        // callPostApi(Logout_POST, {}, {}, (response) => {
        toast.success("User has been logged out!!", { id: "loggedOut" });
        dispatchAuth({ type: LOGOUT });
        callVuPlexLogoutEvent();
        // });
    };
    const handleHelp = () => {
        callGetApiWithoutAuth(Get_StaticPages_List_GET.replaceAll("{pageType}", "HelpAndSupport"), (response) => {
            if (response.errorCode === NO_ERROR) {
                setHelpData(response.responsePacket.pageHtmlContent);
            } else {
                toast.error(response.message, { id: "errorMsg" });
            }
        });
        setShowHelp(true);
    };
    const handleAboutUs = () => {
        callGetApiWithoutAuth(Get_StaticPages_List_GET.replaceAll("{pageType}", "AboutUs"), (response) => {
            if (response.errorCode === NO_ERROR) {
                setAboutUsData(response.responsePacket.pageHtmlContent);
            } else {
                toast.error(response.message, { id: "errorMsg" });
            }
        });
        setShowAboutUs(true);
    };

    const handleWhatsapp = () => {
        window.sendMessageToVuplex("UpdateShowUrl", `https://api.whatsapp.com/send?phone=+916299689386`);
    };

    return (
        <Wrapper>
            <CustomHeader title={"Settings"} />
            <div className="p-3">
                <div className="yellowCard mb-3 mt-4">
                    <div className="innerCard py-3">
                        {/* <div className="d-flex align-items-center justify-content-between" onClick={() => navigate("/kycNew")}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/kycIcon.png" alt="kycIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">KYC</p>
                                    <small className="mb-0 text-white">Complete your KYC</small>
                                </div>
                            </div>
                            <div>
                                <img src="assets/img/rightIcon.png" alt="rightIcon" style={{ width: "18px" }} />
                            </div>
                        </div> */}
                        {/* <div className="customHr my-3"></div> */}
                        <div className="d-flex align-items-center justify-content-between" onClick={handleHelp}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/helpIcon.png" alt="helpIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Help</p>
                                </div>
                            </div>
                            <div>
                                <img src="assets/img/rightIcon.png" alt="rightIcon" style={{ width: "18px" }} />
                            </div>
                        </div>
                        <div className="customHr my-3"></div>
                        <div className="d-flex align-items-center justify-content-between" onClick={handleAboutUs}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/infoIcon.png" alt="infoIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">About Us</p>
                                </div>
                            </div>
                            <div>
                                <img src="assets/img/rightIcon.png" alt="rightIcon" style={{ width: "18px" }} />
                            </div>
                        </div>
                        <div className="customHr my-3"></div>
                        {/* <div className="d-flex align-items-center justify-content-between" onClick={handleWhatsapp}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/whatsapp.png" alt="infoIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Chat Support</p>
                                </div>
                            </div>
                            <div>
                                <img src="assets/img/rightIcon.png" alt="rightIcon" style={{ width: "18px" }} />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="yellowCard mb-3">
                    <div className="innerCard py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/logoutIcon.png" alt="logoutIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Logout</p>
                                </div>
                            </div>
                            <div>
                                <button className="btnRed text-white font-size-18 font-weight-600 py-1 px-3" onClick={handleLogout}>
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalBox
                show={showHelp}
                toggleModel={() => {
                    setShowHelp(false);
                    setHelpData("");
                }}
                modalClass={"sliderBlockModal"}
            >
                <div className="modal-content bg-yellowGradient border-none">
                    <div className="modal-body p-0">
                        <div className=" modal-body-header">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setShowHelp(false);
                                    setHelpData("");
                                }}
                            >
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "15px" }} />
                                </span>
                            </button>
                            <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                Help And Support
                            </h5>
                        </div>
                        <div className="p-2 staticPageText">
                            <div dangerouslySetInnerHTML={{ __html: helpData }} />
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox
                show={showAboutUs}
                toggleModel={() => {
                    setShowAboutUs(false);
                    setAboutUsData("");
                }}
                modalClass={"sliderBlockModal"}
            >
                <div className="modal-content bg-yellowGradient border-none">
                    <div className="modal-body p-0">
                        <div className=" modal-body-header">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setShowAboutUs(false);
                                    setAboutUsData("");
                                }}
                            >
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "15px" }} />
                                </span>
                            </button>
                            <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                About Us
                            </h5>
                        </div>
                        <div className="p-2 staticPageText">
                            <div dangerouslySetInnerHTML={{ __html: aboutUsData }} />
                        </div>
                    </div>
                </div>
            </ModalBox>
        </Wrapper>
    );
};

export default Setting;
