import React, { createContext, useReducer } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AuthTokenKey, UserDetailsKey, getDataFromStore } from "utils/DataStore";
import "./App.css";
import AppRoutes from "./AppRoutes";
import SideMenu from "./components/common/SideMenu";
import { userReducer } from "./reducer/reducers/userReducer";
import { utilityReducer } from "./reducer/reducers/utilityReducer";
import useOnline from "customHooks/useOnline";
import ModalBox from "components/common/Modal";

export const GlobalContext = createContext();

const toastDefaultOptionsStyle = {
    borderRadius: "5px",
    fontSize: "12px",
    background: "#9284b9",
    color: "#ffffff",
};

function App() {
    var authToken = getDataFromStore(AuthTokenKey);
    var userDetails = getDataFromStore(UserDetailsKey);
    const [authState, dispatchAuth] = useReducer(userReducer, {
        isAuthenticated: authToken !== null ? true : false,
        authToken: authToken,
        userDetails: userDetails,
    });

    const [utilityState, dispatchUtility] = useReducer(utilityReducer, {
        walletDetails: null,
        activeWalletTab: "Add Cash",
        showSideMenu: false,
    });

    const isOnline = useOnline();
    
    return (
        <BrowserRouter>
            <GlobalContext.Provider
                value={{
                    authState,
                    dispatchAuth,
                    utilityState,
                    dispatchUtility,
                }}
            >
                {
                    <ModalBox show={!isOnline} modalClass={"connection-modal"}>
                        <div className="modal-content bg-yellowGradient border-none">
                            <div className="modal-body pb-0 pt-2">
                                <div className="font-size-28 text-white">No Internet</div>
                                <div className="font-size-16 text-white dots-loading mt-2 ">Trying to reconnect</div>
                            </div>
                        </div>
                    </ModalBox>
                }
                <AppRoutes />
                <SideMenu />
            </GlobalContext.Provider>
            <Toaster
                toastOptions={{
                    className: "",
                    duration: 2000,
                    style: toastDefaultOptionsStyle,
                    success: {
                        duration: 2000,
                    },
                }}
            />
        </BrowserRouter>
    );
}

export default App;
