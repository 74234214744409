import React, { useEffect, useState } from "react";

const useOnline = () => {
    const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);

    const handleOnlineStatus = () => {
        setOnlineStatus(navigator.onLine);
        if (navigator.onLine === true) {
            // window.location.reload();
            // window.open(window.location.origin + "/index.html");
        }
    }

    useEffect(() => {
        window.addEventListener("offline", handleOnlineStatus);
        window.addEventListener("online", handleOnlineStatus);

        return () => {
            window.removeEventListener("offline", handleOnlineStatus);
            window.removeEventListener("online", handleOnlineStatus);
        };
    }, []);

    return onlineStatus;
};

export default useOnline;
