import React, { useEffect, useState } from "react";
import { Wallet_Withdraw_Request_List_POST, Withdraw_Money_Request_Details_Get } from "../api/ApiConst";
import { callGetApi, callPostApi } from "../api/ApiCaller";
import { NO_ERROR } from "../api/constants";
import { toast } from "react-hot-toast";
import dateUtilities from "../utils/DateUtilities";
import ModalBox from "./common/Modal";
import * as staticConstants from "../constants/staticConstants";
import Loader from "./common/Loader";
import { convertAmount } from "../utils/CurrencyUtils";

const WithdrawableList = ({ showWithdrawableList, handleToggleShowWithdrawableList }) => {
    const [showWithdrawableTransactionDetail, setShowWithdrawableTransactionDetail] = useState(false);
    const [withdrawableTransactionDetail, setWithdrawableTransactionDetail] = useState(null);
    const [withdrawableList, setWithdrawableList] = useState(null);
    const [withdrawableListDetails, setWithdrawableListDetails] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        setWithdrawableList(null);
        setCurrentPage(0);
        setWithdrawableListDetails(null);
        setLoading("preData");
        getList();
    }, []);

    const getList = () => {
        const rObj = {
            pageNumber: currentPage,
            pageSize: 10,
            search: "",
        };
        if (!loading) {
            setLoading("moreData");
        }
        callPostApi(Wallet_Withdraw_Request_List_POST, rObj, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                if (currentPage === 0) {
                    setWithdrawableList(response.responsePacket.data);
                } else {
                    const withdrawableListClone = [...withdrawableList, ...response.responsePacket.data];
                    setWithdrawableList(withdrawableListClone);
                }
                setCurrentPage(currentPage + 1);
                if (!withdrawableListDetails) {
                    setWithdrawableListDetails({
                        recordsFiltered: response.responsePacket.recordsFiltered,
                        recordsTotal: response.responsePacket.recordsTotal,
                        total: response.responsePacket.total,
                    });
                }
                setLoading(null);
            } else {
                toast.error(response.message, { id: "withdrawRequestList" });
                setLoading(null);
            }
        });
    };

    const handleShowSingleWithdrawalDetail = (withdrawRequestId) => {
        setShowWithdrawableTransactionDetail(true);
        let url = Withdraw_Money_Request_Details_Get.replaceAll("{withdrawRequestId}", withdrawRequestId);
        setLoading("details");
        callGetApi(url, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                setWithdrawableTransactionDetail(response.responsePacket);
                setLoading(null);
            } else {
                setLoading(null);
                toast.error(response.message, { id: "withdrawMoneyList" });
            }
        });
    };

    return (
        <>
            {showWithdrawableList && (
                <ModalBox show={showWithdrawableList} toggleModel={handleToggleShowWithdrawableList} modalClass={"withdrawableListModal-listModal  sliderBlockModal"}>
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body p-0">
                            <div className=" modal-body-header">
                                <button type="button" className="btn-close" style={{ right: "15px" }} onClick={handleToggleShowWithdrawableList}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/modal-close.png" alt="" style={{ width: "15px" }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                    Withdrawable List
                                </h5>
                            </div>

                            <div className="modal-body-content">
                                {loading === "preData" ? (
                                    <Loader />
                                ) : (
                                    withdrawableList &&
                                    (withdrawableList.length > 0 ? (
                                        withdrawableList.map((transaction, i) => (
                                            <div className="rounded tail-bg px-3 py-2 cursor-pointer" key={i} onClick={() => handleShowSingleWithdrawalDetail(transaction.withdrawRequestId)}>
                                                <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <div className="text-white font-size-12">
                                                        <span>Txn # </span>
                                                        <span className="text-yellow ml-2">{transaction.id}</span>
                                                    </div>
                                                    <div className="text-white font-size-12">
                                                        <span
                                                            className={`ml-2 ${
                                                                transaction.withdrawTransactionStatus.toLowerCase() === staticConstants.PENDING
                                                                    ? "text-yellow"
                                                                    : transaction.withdrawTransactionStatus.toLowerCase() === staticConstants.APPROVED
                                                                    ? "text-green"
                                                                    : "text-danger"
                                                            }`}
                                                        >
                                                            {transaction.withdrawTransactionStatus}
                                                        </span>
                                                    </div>
                                                    <div className="text-white font-size-12 font-weight-600">
                                                        <span className={`${transaction.drCr === "Cr" ? "text-danger" : "text-green"} ml-2`}>{convertAmount(transaction.amount)}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="text-white font-size-12">
                                                        <span>Transfer Type</span>
                                                        <span className="text-yellow ml-2">{transaction.transferType}</span>
                                                    </div>
                                                    <div className="text-white font-size-12">
                                                        <span className="text-yellow">
                                                            <i className="fa fa-calendar text-white mx-2" aria-hidden="true"></i>
                                                            {dateUtilities.formattedDate(transaction.createdAtTimeStamp, "MMM dd, uuuu, hh:mm:ss aa")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="my-3 text-white text-center">No Data Available!</div>
                                    ))
                                )}
                                {loading === "moreData" ? (
                                    <Loader />
                                ) : (
                                    withdrawableListDetails &&
                                    withdrawableListDetails.recordsTotal > withdrawableList.length && (
                                        <div className="pt-3 customised-btn-purple text-center">
                                            <button className="btn text-white" onClick={getList}>
                                                Load More
                                            </button>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )}
            {/* Withdrawable Transaction detail */}
            {showWithdrawableTransactionDetail && (
                <ModalBox show={showWithdrawableTransactionDetail} toggleModel={() => setShowWithdrawableTransactionDetail(false)} modalClass={"sliderBlockModal sliderBlockModal"}>
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body p-0">
                            <div className=" modal-body-header">
                                <button type="button" className="btn-close" style={{ right: "15px" }} onClick={() => setShowWithdrawableTransactionDetail(false)}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/modal-close.png" alt="" style={{ width: 25 }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                    Transaction Details
                                </h5>
                            </div>

                            <div className="px-3 pb-3">
                                {loading === "details" || !withdrawableTransactionDetail ? (
                                    <Loader />
                                ) : (
                                    <div className="rounded tail-bg px-3 py-2">
                                        <div className="text-white font-size-14 custom-border-light d-flex align-items-center justify-content-between">
                                            <span>Txn # </span>
                                            <span className="text-yellow py-2">{withdrawableTransactionDetail.transactionId}</span>
                                        </div>
                                        <div className="text-white font-size-14 py-2 custom-border-light d-flex align-items-center justify-content-between">
                                            <span>Amount</span>
                                            <span className="text-green ml-2">{convertAmount(withdrawableTransactionDetail.amount)}</span>
                                        </div>

                                        <div className="text-white font-size-14  py-2 custom-border-light d-flex align-items-center justify-content-between">
                                            <span>Account Type</span>
                                            <span className="text-green ml-2">{withdrawableTransactionDetail.accountType}</span>
                                        </div>
                                        <div className="text-white font-size-14 py-2 custom-border-light d-flex align-items-center justify-content-between">
                                            <span>Transfer Type</span>
                                            <span className="text-yellow ml-2">{withdrawableTransactionDetail.transferType}</span>
                                        </div>
                                        <div className="text-white font-size-14 py-2 custom-border-light d-flex align-items-center justify-content-between">
                                            <span>Transaction Status</span>
                                            <span
                                                className={`ml-2 ${
                                                    withdrawableTransactionDetail.withdrawTransactionStatus.toLowerCase() === staticConstants.PENDING
                                                        ? "text-yellow"
                                                        : withdrawableTransactionDetail.withdrawTransactionStatus.toLowerCase() === staticConstants.APPROVED
                                                        ? "text-green"
                                                        : "text-danger"
                                                }`}
                                            >
                                                {withdrawableTransactionDetail.withdrawTransactionStatus}
                                            </span>
                                        </div>
                                        {withdrawableTransactionDetail.transferType === "UPI" ? (
                                            <>
                                                <div className="text-white font-size-14 py-2 custom-border-light d-flex align-items-center justify-content-between">
                                                    <span>UPI ID</span>
                                                    <span className="text-green ml-2">{withdrawableTransactionDetail.upiId}</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="text-white font-size-14  py-2 custom-border-light d-flex align-items-center justify-content-between">
                                                    <span>Bank Name</span>
                                                    <span className="text-green ml-2">{withdrawableTransactionDetail.bankName}</span>
                                                </div>
                                                <div className="text-white font-size-14  py-2 custom-border-light d-flex align-items-center justify-content-between">
                                                    <span>Account Number</span>
                                                    <span className="text-green ml-2">{withdrawableTransactionDetail.accountNumber}</span>
                                                </div>
                                                <div className="text-white font-size-14 py-2 custom-border-light d-flex align-items-center justify-content-between">
                                                    <span>Account Holder Name</span>
                                                    <span className="text-green ml-2">{withdrawableTransactionDetail.accountHolderName}</span>
                                                </div>
                                                <div className="text-white font-size-14 py-2 custom-border-light d-flex align-items-center justify-content-between">
                                                    <span>IFSC Code</span>
                                                    <span className="text-green ml-2">{withdrawableTransactionDetail.ifscCode}</span>
                                                </div>
                                            </>
                                        )}
                                        <div className="text-white font-size-14 pt-2">
                                            <span className="text-yellow">
                                                <i className="fa fa-calendar text-white mx-2" aria-hidden="true"></i>
                                                {dateUtilities.formattedDate(withdrawableTransactionDetail.createdAtTimeStamp, "MMM dd, uuuu, hh:mm:ss aa")}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )}
        </>
    );
};

export default WithdrawableList;
