import React, { useContext } from "react";
import * as StaticConstants from "../constants/staticConstants";
import { Link, useLocation } from "react-router-dom";
import { callGetApi } from "../api/ApiCaller";
import { NO_ERROR } from "../api/constants";
import { SET_WALLET_DETAILS } from "../reducer/actions/utilityActions";
import { Wallet_Balance_GET } from "../api/ApiConst";
import { GlobalContext } from "../App";
import { toast } from "react-hot-toast";

const FooterMenu = () => {
    const { dispatchUtility } = useContext(GlobalContext);
    const location = useLocation();

    const getWalletDetails = () => {
        callGetApi(Wallet_Balance_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                dispatchUtility({ type: SET_WALLET_DETAILS, payload: response.responsePacket });
            } else {
                toast.error(response.message, {
                    id: "getWalletBalance",
                });
            }
        });
    };

    return (
        <div className="osahan-menu-fotter active-menu fixed-bottom text-center">
            <ul className="list-unstyled mb-0">
                <li className={`${location.pathname === "/" ? "active" : ""}`}>
                    <Link to="/">
                        <span className="span" onClick={getWalletDetails}>
                            <svg width="26px" height="26px" viewBox="0 0 95.421 88.739">
                                <path
                                    id="Path_5085"
                                    data-name="Path 5085"
                                    d="M4823.1,2170.38l-36.252,32.6c-6.13,6.13-15.6-3.344-9.474-9.475l39.026-37.063c2.508-2.507,10.614-2.507,13.4,0l39.026,37.063c6.131,6.13-3.344,15.6-9.474,9.475l-36.252-32.6Zm-33.186,34.749V2243.3h20.9v-22.294h24.574V2243.3h20.9v-38.176l-33.186-29.26Z"
                                    transform="translate(-4775.394 -2154.566)"
                                    fill="#6659B5"
                                    fillRule="evenodd"
                                />
                            </svg>
                        </span>
                        {StaticConstants.HOME}
                    </Link>
                </li>
                <li className={`${location.pathname === "/wallet" ? "active" : ""}`}>
                    <Link to="/wallet">
                        <span className="span" onClick={getWalletDetails}>
                            <svg width="26px" height="26px" viewBox="0 0 93.629 88.74">
                                <g id="XTI81D" transform="translate(-5157.755 -2154.565)">
                                    <g id="Group_3511" data-name="Group 3511">
                                        <path
                                            id="Path_5088"
                                            data-name="Path 5088"
                                            d="M5251.377,2196.159c-2.256,0-4.286,0-6.315,0-6.161,0-12.322-.055-18.483,0a14.178,14.178,0,0,0-.509,28.348c7.8.122,15.6.036,23.4.042h1.9c0,2.287,0,4.409,0,6.53,0,2.534.015,5.068.009,7.6-.008,3.069-1.5,4.607-4.553,4.609q-27.053.022-54.107.013-14.981,0-29.96,0c-3.585,0-5-1.358-5-4.9q-.007-28.023-.011-56.046c0-3.575,1.334-4.931,4.955-4.932q42.183-.018,84.366-.009c2.717,0,4.286,1.545,4.3,4.223C5251.39,2186.4,5251.377,2191.164,5251.377,2196.159Z"
                                            fill="#6659B5"
                                        />
                                        <path
                                            id="Path_5089"
                                            data-name="Path 5089"
                                            d="M5241.964,2173.764h-75.281l-.05-.208c.925-.288,1.844-.6,2.776-.861q31.515-8.925,63.033-17.84c2.8-.792,4.157,0,4.968,2.84,1.336,4.677,2.692,9.348,4.033,14.023C5241.618,2172.327,5241.757,2172.947,5241.964,2173.764Z"
                                            fill="#6659B5"
                                        />
                                        <path id="Path_5090" data-name="Path 5090" d="M5218.239,2210.323a8.373,8.373,0,1,1,8.427,8.375A8.31,8.31,0,0,1,5218.239,2210.323Z" fill="#6659B5" />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        {StaticConstants.WALLET}
                    </Link>
                </li>
                <li className={`${location.pathname === "/account" ? "active" : ""}`}>
                    <Link to="/profile" onClick={getWalletDetails}>
                        <span className="span">
                            <svg width="26px" height="26px" viewBox="0 0 79.794 88.74">
                                <g id="A2gJeN" transform="translate(-5529.407 -2154.566)">
                                    <g id="Group_3509" data-name="Group 3509">
                                        <path
                                            id="Path_5086"
                                            data-name="Path 5086"
                                            d="M5569.261,2243.291q-15.377,0-30.751-.006c-6.3-.013-9.125-2.731-9.1-8.983a105.811,105.811,0,0,1,.527-13.671,25.75,25.75,0,0,1,20.409-21.368,6.374,6.374,0,0,1,4.056.874c9.957,5.926,19.806,5.929,29.771,0a6.6,6.6,0,0,1,4.4-.745,26.165,26.165,0,0,1,20.54,24.93c.134,3.577.1,7.166.014,10.746-.122,5.331-2.993,8.179-8.375,8.209C5590.255,2243.332,5579.758,2243.291,5569.261,2243.291Z"
                                            fill="#6659b5"
                                        />
                                        <path id="Path_5087" data-name="Path 5087" d="M5592.113,2177.263a22.848,22.848,0,1,1-23.06-22.7A22.986,22.986,0,0,1,5592.113,2177.263Z" fill="#6659b5" />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        {StaticConstants.ACCOUNT}
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default FooterMenu;
