// import Cookies from "universal-cookie";

export const AuthTokenKey = "authToken";
export const UserDetailsKey = "userDetails";

export const setDataInStore = (key, valueJson) => {
    if(key === null || key === undefined || key === 'undefined') return;
    if(valueJson === null || valueJson === undefined || valueJson === 'undefined') return;
    // const cookies = new Cookies();
    // var expireDate = new Date();
    // expireDate.setDate(expireDate.getDate() + 30);
    // cookies.set(key, valueJson, { path: "/", expires: expireDate });
    localStorage.setItem(key, JSON.stringify(valueJson));
};

export const getDataFromStore = (key) => {
    // const cookies = new Cookies();
    // if (cookies.get(key) !== null && cookies.get(key) !== undefined) {
    //     return cookies.get(key);
    // }
    var value = localStorage.getItem(key);
    if (value !== null && value !== undefined && value !== "undefined") {
        return JSON.parse(value);
    }
    return null;
};

export const deleteDataFromStore = (key) => {
    // const cookies = new Cookies();
    // cookies.remove(key);
    localStorage.removeItem(key);
};

export const clearDataFromStore = () => {
    // const cookies = new Cookies();
    // cookies.remove();
    localStorage.clear();
};

window._setDataInStore = (key, valueJson) => {
    setDataInStore(key, valueJson);
};

window._getDataFromStore = (key) => {
    return getDataFromStore(key);
};

window._deleteDataFromStore = (key) => {
    deleteDataFromStore(key);
};

window._clearDataFromStore = () => {
    clearDataFromStore();
};