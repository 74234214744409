import React from "react";
import { useLocation } from "react-router-dom";

const Wrapper = (props) => {
    const location = useLocation();

    return (
        <div className="wrapper-body wrapper-bg">
            <div className={location.pathname === "/" ? "wrapper-main-box-position" : "wrapper-main"}>
                <div className={location.pathname === "/account" ? "wrapper-main-box" : "wrapper-main-box"}>{props.children}</div>
            </div>
        </div>
    );
};

export default Wrapper;
