import React, { useContext } from "react";
import { GlobalContext } from "../../../App";
import * as staticConstants from "../../../constants/staticConstants";

const KYCStatusCard = ({ handleShowCompleteKYCModal }) => {
    const { authState } = useContext(GlobalContext);

    return (
        <div className="balance-card bg-yellowGradient-dark p-3">
            {authState.userDetails.kycVerificationStatus === "Approved" ? (
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="d-block">
                            <h6 className="text-green mb-0 font-size-16 font-weight-600">
                                {staticConstants.KYC_VERIFIED}
                                <span className="ml-2">
                                    <i className="fa fa-check font-size-14" aria-hidden="true"></i>
                                </span>
                            </h6>
                            <p className="mb-0 mt-2 text-light font-size-12 font-weight-500">{staticConstants.PAN_AADHAR_VERIFICATION_REQUIRED}</p>
                        </div>
                        <div className="ml-4">
                            <img src="assets/img/pancard2.png" alt="pancard" style={{ width: 80 }} />
                        </div>
                    </div>
                    <div className="text-center custom-green-btn pt-4 px-4">
                        <button className="btn px-4" onClick={handleShowCompleteKYCModal}>
                            {staticConstants.VIEW_DOCUMENTS}
                        </button>
                    </div>
                </div>
            ) : authState.userDetails.kycVerificationStatus === "Pending" ? (
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="d-block">
                            <h6 className="text-green mb-0 font-size-16 font-weight-600">
                                {staticConstants.KYC_UNDER_REVIEW}
                                <span className="ml-2">
                                    <i className="fa font-size-12 fa-search" />
                                </span>
                            </h6>
                            <p className="mb-0 mt-2 text-light font-size-12 font-weight-500">{staticConstants.YOUR_KYC_DOCUMENT_ARE_UNDER_REVIEW}</p>
                        </div>
                        <div className="ml-4">
                            <img src="assets/img/pancard2.png" alt="pancard" style={{ width: 80 }} />
                        </div>
                    </div>
                    <div className="text-center custom-green-btn pt-4 px-4">
                        <button className="btn px-4" onClick={handleShowCompleteKYCModal}>
                            {staticConstants.VERIFY_NOW}
                        </button>
                    </div>
                </div>
            ) : authState.userDetails.kycVerificationStatus === "Rejected" ? (
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="d-block">
                            <h6 className="text-yellow mb-0 font-size-16 font-weight-600">
                                {staticConstants.KYC_VERIFICATION_FAILED}
                                <span className="ml-2">
                                    <i className="fa fa-times font-size-14" />
                                </span>
                            </h6>
                            <p className="mb-0 mt-2 text-light font-size-12 font-weight-500">{staticConstants.UNFORTUNATELY_VERIFICATION_FAILED}</p>
                        </div>
                        <div className="ml-4">
                            <img src="assets/img/pancard2.png" alt="pancard" style={{ width: 80 }} />
                        </div>
                    </div>
                    <div className="text-center custom-green-btn pt-4 px-4">
                        <button className="btn px-4" onClick={handleShowCompleteKYCModal}>
                            {staticConstants.VERIFY_AGAIN}
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="d-block">
                            <h6 className="text-green mb-0 font-size-16 font-weight-600">{staticConstants.COMPLETE_KYC}</h6>
                            <p className="mb-0 mt-2 text-light font-size-12 font-weight-500">{staticConstants.PAN_AADHAR_VERIFICATION_REQUIRED}</p>
                        </div>
                        <div className="ml-4">
                            <img src="assets/img/pancard2.png" alt="pancard" style={{ width: 80 }} />
                        </div>
                    </div>
                    <div className="text-center custom-green-btn pt-4 px-4">
                        <button className="btn px-4" onClick={handleShowCompleteKYCModal}>
                            {staticConstants.VERIFY_NOW}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default KYCStatusCard;
