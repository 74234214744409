import React, { useContext, useState } from "react";
import { GlobalContext } from "../../App";
import { HIDE_SIDE_MENU, SET_ACTIVE_WALLET_TAB } from "../../reducer/actions/utilityActions";
import ModalBox from "./Modal";
import { LOGOUT } from "../../reducer/actions/userActions";
import * as staticConstants from "../../constants/staticConstants";
import { useNavigate } from "react-router-dom";
import WithdrawableList from "../../components/WithdrawableList";
import { toast } from "react-hot-toast";
import { callPostApi } from "api/ApiCaller";
import { Logout_POST } from "api/ApiConst";

const SideMenu = () => {
    const { utilityState, dispatchAuth, dispatchUtility } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [showWithdrawableList, setShowWithdrawableList] = useState(false);

    const handleHideSideMenu = () => {
        dispatchUtility({ type: HIDE_SIDE_MENU });
    };

    const callVuPlexLogoutEvent = () => {
        window.sendMessageToVuplex("Logout", {});
    };

    const handleLogout = () => {
        // callPostApi(Logout_POST, {}, {}, (response) => {
        toast.success("User has been logged out!!", { id: "loggedOut" });
        dispatchAuth({ type: LOGOUT });
        callVuPlexLogoutEvent();
        handleHideSideMenu();
        // });
    };

    const handleRedirect = (tabName) => {
        navigate("/wallet");
        dispatchUtility({ type: SET_ACTIVE_WALLET_TAB, payload: tabName });
        handleHideSideMenu();
    };

    const handleShowWithdrawableList = () => {
        setShowWithdrawableList(true);
        handleHideSideMenu();
    };

    const handleWhatsapp = () => {
        window.sendMessageToVuplex("UpdateShowUrl", "https://api.whatsapp.com/send?phone=+917021669019");
    };

    return (
        <>
            <ModalBox show={utilityState.showSideMenu} toggleModel={handleHideSideMenu} modalClass={"setting-modal"}>
                <div className="modal-content border-none">
                    <div className="modal-body px-3 pb-3 pt-0">
                        {/* <div className="d-flex align-items-center cursor-pointer">
                            <span onClick={handleHideSideMenu}>
                                <img src="/assets/img/back-arrow.png" style={{ width: "20px" }} className="mr-3" />
                            </span>
                            <h5 className="text-white mb-0 ml-2 font-weight-600">SETTINGS</h5>
                        </div> */}
                        <div className="dashboard-header" style={{ position: "unset", width: "auto" }}>
                            <div className="px-2 d-flex align-items-center justify-content-between">
                                <div className="d-flex ">
                                    <h5 className="font-size-16 text-white font-weight-600 d-flex align-items-center mb-0">
                                        <span className="mr-4 cursor-pointer" onClick={handleHideSideMenu}>
                                            <img src="assets/img/back-left-arrow.png" alt="back left arrow" style={{ width: 13 }} />
                                        </span>
                                        <span>Settings</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="custom-link mt-0">
                            <ul>
                                <li onClick={() => handleRedirect("Add Cash")}>
                                    <span className="custom-nav-link">
                                        <span className="icon mr-3">
                                            <img src="/assets/img/add-cash.png" alt="icon" />
                                        </span>
                                        {staticConstants.ADD_CASH}
                                    </span>
                                </li>
                                <li onClick={() => handleRedirect("History")}>
                                    <span className="custom-nav-link">
                                        <span className="icon mr-3">
                                            <img src="/assets/img/tran-history.png" alt="icon" />
                                        </span>
                                        Transactions
                                    </span>
                                </li>
                                <li onClick={() => handleRedirect("Withdraw")}>
                                    <span className="custom-nav-link">
                                        <span className="icon mr-3">
                                            <img src="/assets/img/withdraw-icon.png" alt="icon" />
                                        </span>
                                        Withdraw Cash
                                    </span>
                                </li>
                                <li onClick={handleShowWithdrawableList}>
                                    <span className="custom-nav-link">
                                        <span className="icon mr-3">
                                            <img src="/assets/img/withdraw-icon.png" alt="icon" />
                                        </span>
                                        Withdrawal History
                                    </span>
                                </li>
                                <li onClick={handleWhatsapp}>
                                    <span className="custom-nav-link">
                                        <span className="icon mr-3">
                                            <img src="/assets/img/chat-icon.png" alt="icon" />
                                        </span>
                                        Chat On Whatsapp
                                    </span>
                                </li>
                            </ul>
                            <div className="pt-3 custom-green-btn">
                                <button className="btn w-100 py-2" onClick={handleLogout}>
                                    LOG OUT
                                    <span>
                                        <img src="/assets/img/logout.png" alt="logout" style={{ width: "25px" }} />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <span className="text-white font-size-12 font-weight-300 versionBottom">Version {staticConstants.VERSION}</span>
            </ModalBox>
            {showWithdrawableList && <WithdrawableList showWithdrawableList={showWithdrawableList} handleToggleShowWithdrawableList={() => setShowWithdrawableList(!showWithdrawableList)} />}
        </>
    );
};

export default SideMenu;
