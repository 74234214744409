import { BUTTON_DISABLE, BUTTON_ENABLE, HIDE_SIDE_MENU, SET_ACTIVE_WALLET_TAB, SET_WALLET_DETAILS, SHOW_SIDE_MENU } from "../actions/utilityActions";

export const utilityReducer = (state, action) => {
    switch (action.type) {
        case SET_ACTIVE_WALLET_TAB:
            return {
                ...state,
                activeWalletTab: action.payload,
            };
        case SHOW_SIDE_MENU:
            return {
                ...state,
                showSideMenu: true,
            };
        case HIDE_SIDE_MENU:
            return {
                ...state,
                showSideMenu: false,
            };
        case SET_WALLET_DETAILS:
            return {
                ...state,
                walletDetails: action.payload,
            };
        case BUTTON_DISABLE:
            return {
                ...state,
                buttonDisable: true,
            };
        case BUTTON_ENABLE:
            return {
                ...state,
                buttonDisable: false,
            };

        default:
            return state;
    }
};
