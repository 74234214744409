import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { callGetApiWithoutAuth, callPostApi } from "../../../api/ApiCaller";
import {
    Get_KYC_Document_List_POST,
    Get_StaticPages_List_GET,
    Upload_Aadhar_In_Multipart_POST,
    Upload_File_In_Multipart_POST,
    Upload_File_POST,
    Upload_KYC_Document_POST,
    Upload_PAN_In_Multipart_POST,
} from "../../../api/ApiConst";
import { NO_ERROR } from "../../../api/constants";
import * as conditionalConstants from "../../../constants/conditionalConstants";
import * as staticConstants from "../../../constants/staticConstants";
import { insertSpaceInKeyDirect, isNumeric, isPanCard, isSpecialChar } from "../../../utils/TextUtils";
import Loader from "../../common/Loader";
import ModalBox from "../../common/Modal";
import KYCStatusCard from "./KYCStatusCard";
import { GlobalContext } from "App";

const Kyc = () => {
    const { authState } = useContext(GlobalContext);

    const navigate = useNavigate();
    const [showTermsConditionsModal, setShowTermsConditionsModal] = useState(false);
    const [termsAndCondition, setTermsAndCondition] = useState("");
    const [loading, setLoading] = useState(null);
    const [KYCDocumentList, setKYCDocumentList] = useState(null);
    const [showCompleteKYCModal, setShowCompleteKYCModal] = useState(false);
    const [showUploadImageModal, setShowUploadImageModal] = useState(false);
    const [showKYCDocsSubmitSuccess, setShowKYCDocsSubmitSuccess] = useState(false);
    const [showUploadFrontBackImageModal, setShowUploadFrontBackImageModal] = useState(false);
    const [uploadKYCDocFor, setUploadKYCDocFor] = useState(null);
    const [selectedImageType, setSelectedImageType] = useState(null);
    const [aadharFrontImage, setAadharFrontImage] = useState("");
    const [aadharBackImage, setAadharBackImage] = useState("");
    const [panFrontImage, setPanFrontImage] = useState("");
    const [termsConditionsChecked, setTermsConditionsChecked] = useState(false);
    const [KYCDocNumber, setKYCDocNumber] = useState("");
    const fileRefAadharFront = useRef(null);
    const fileRefAadharBack = useRef(null);
    const fileRefPANFront = useRef(null);
    const [showEnlargedImage, setShowEnlargedImage] = useState(false);
    const [selectedImg, setSelectedImg] = useState(null);
    const [panCardImageURL, setPanCardImageURL] = useState("");
    const [aadharFrontURL, setAadharFrontURL] = useState("");
    const [aadharBackURL, setAadharBackURL] = useState("");
    const [panCardImageData, setPanCardImageData] = useState("");
    const [aadharFrontData, setAadharFrontData] = useState("");
    const [aadharBackData, setAadharBackData] = useState("");
    const panCardRef = useRef(null);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        callGetApiWithoutAuth(Get_StaticPages_List_GET.replaceAll("{pageType}", "TermsAndConditions"), (response) => {
            if (response.errorCode === NO_ERROR) {
                setTermsAndCondition(response.responsePacket.pageHtmlContent);
            } else {
                toast.error(response.message, {
                    id: "staticPages",
                });
            }
        });
    }, []);

    useEffect(() => {
        if (!showCompleteKYCModal) {
            handleClearForm();
        }
    }, [showCompleteKYCModal]);

    useEffect(() => {
        if (!showUploadFrontBackImageModal) {
            setAadharFrontImage("");
            setAadharBackImage("");
            setPanFrontImage("");
            setKYCDocNumber("");
        }
    }, [showUploadFrontBackImageModal]);

    useEffect(() => {
        callPostApi(Get_KYC_Document_List_POST, {}, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                setKYCDocumentList(response.responsePacket);
            } else {
                toast.error(response.message, { id: "kycDocList" });
            }
        });
    }, [showCompleteKYCModal, selectedImageType]);

    const handleClearForm = () => {
        setAadharFrontImage("");
        setAadharBackImage("");
        setPanFrontImage("");
        setShowCompleteKYCModal(false);
        setShowUploadImageModal(false);
        setShowUploadFrontBackImageModal(false);
        setKYCDocNumber("");
        // setLoader(false);
        setSelectedImageType(null);
    };

    const handleGalleryOrCameraSelection = (type) => {
        setSelectedImageType(type);
        // setShowUploadImageModal(true);
        if (type === "aadharFront") {
            handleAadharFrontImageClick();
        } else if (type === "aadharBack") {
            handleAadharBackImageClick();
        } else {
            handlePANFrontImageClick();
        }
    };

    const handleImageSelection = (fileEvent) => {
        const { files } = fileEvent.target;

        var _validFileExtensions = [".jpg", ".jpeg", ".gif", ".png"];
        if (files && files[0]) {
            var reader = new FileReader();
            reader.onload = function (event) {
                const { result } = event.target;
                const imageFileData = {
                    fileName: files[0].name,
                    fileBase64: result,
                };
                // Validating image extensions
                if (imageFileData.fileName.length > 0) {
                    var blnValid = false;
                    for (var j = 0; j < _validFileExtensions.length; j++) {
                        var sCurExtension = _validFileExtensions[j];
                        if (imageFileData.fileName.substr(imageFileData.fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                            blnValid = true;
                        }
                    }

                    if (!blnValid) {
                        toast.error("Sorry, " + imageFileData.fileName + " is invalid. Allowed extensions are: " + _validFileExtensions.join(", "), { id: "invalidExtension" });
                        return false;
                    }
                }
                // -------------
                if (selectedImageType === "aadharFront") {
                    fileRefAadharFront.current.setAttribute("src", result);
                    setAadharFrontImage(result);
                    setAadharFrontData(files[0]);
                } else if (selectedImageType === "aadharBack") {
                    fileRefAadharBack.current.setAttribute("src", result);
                    setAadharBackImage(result);
                    setAadharBackData(files[0]);
                } else if (selectedImageType === "panFront") {
                    fileRefPANFront.current.setAttribute("src", result);
                    setPanFrontImage(result);
                    setPanCardImageData(files[0]);
                }
                // handleUploadKYCImage(imageFileData);
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const handleAadharFrontImageClick = (event) => {
        fileRefAadharFront.current.click();
    };

    const handleAadharBackImageClick = (event) => {
        fileRefAadharBack.current.click();
    };

    const handlePANFrontImageClick = (event) => {
        fileRefPANFront.current.click();
    };

    const handleUploadAadharImage = () => {
        setLoader(true);
        setLoading(selectedImageType);
        const formData = new FormData();
        formData.append("front_image", aadharFrontData);
        formData.append("back_image", aadharBackData);

        // if (imageFileData.fileBase64) {
        callPostApi(Upload_Aadhar_In_Multipart_POST, formData, {}, (fileResponse) => {
            if (fileResponse.errorCode === NO_ERROR) {
                const obj = {
                    // base64URL: imageFileData.fileBase64,
                    imageURL: fileResponse.responsePacket,
                };
                if (selectedImageType === "panFront") {
                    setPanFrontImage(obj);
                } else if (selectedImageType === "aadharFront") {
                    setAadharFrontImage(obj);
                } else if (selectedImageType === "aadharBack") {
                    setAadharBackImage(obj);
                }
                toast.success(fileResponse.message, {
                    id: "uploadKycImage",
                });
                setSelectedImageType(null);
                setLoader(false);
                handleClearForm();
                setShowCompleteKYCModal(true);
                // setShowKYCDocsSubmitSuccess(true);
                setLoading(null);
            } else {
                toast.error(fileResponse.message, { id: "uploadKycImage" });
                setLoading(null);
                setLoader(false);
                setSelectedImageType(null);
            }
        });
        // }
    };

    const handleUploadPanImage = () => {
        setLoader(true);
        setLoading(selectedImageType);
        const formData = new FormData();
        formData.append("front_image", panCardImageData);
        callPostApi(Upload_PAN_In_Multipart_POST, formData, {}, (fileResponse) => {
            if (fileResponse.errorCode === NO_ERROR) {
                const obj = {
                    // base64URL: imageFileData.fileBase64,
                    imageURL: fileResponse.responsePacket,
                };
                if (selectedImageType === "panFront") {
                    setPanFrontImage(obj);
                } else if (selectedImageType === "aadharFront") {
                    setAadharFrontImage(obj);
                } else if (selectedImageType === "aadharBack") {
                    setAadharBackImage(obj);
                }
                toast.success(fileResponse.message, {
                    id: "uploadKycImage",
                });
                setSelectedImageType(null);
                handleClearForm();
                setLoader(false);
                setShowCompleteKYCModal(true);
                // setShowKYCDocsSubmitSuccess(true);
                setLoading(null);
            } else {
                toast.error(fileResponse.message, { id: "uploadKycImage" });
                setLoading(null);
                setLoader(false);
                setSelectedImageType(null);
            }
        });
        // }
    };

    const checkConditionsForSubmission = () => {
        if (KYCDocNumber === "") {
            toast(`Please enter ${uploadKYCDocFor === staticConstants.PAN_CARD_ ? "PAN" : "Aadhar"} Card Number`, { id: "invalidDetails" });
            return false;
        }
        if (!termsConditionsChecked) {
            toast(conditionalConstants.PLEASE_ACCEPT_TERMS_AND_CONDITIONS, {
                id: "invalidDetails",
            });
            return false;
        }
        return true;
    };
    const checkConditionsForPanSubmission = () => {
        if (!termsConditionsChecked) {
            toast(conditionalConstants.PLEASE_ACCEPT_TERMS_AND_CONDITIONS, {
                id: "invalidDetails",
            });
            return false;
        }
        return true;
    };

    const handleSubmitUploadAadharDocument = () => {
        setLoading("uploading");
        if (checkConditionsForSubmission()) {
            if (aadharFrontURL && aadharBackURL) {
                const rObj = {
                    frontImageUrl: aadharFrontURL,
                    backImageUrl: aadharBackURL,
                    // documentNumber: KYCDocNumber,
                    // documentType: "AadharCard",
                };
                callPostApi(Upload_KYC_Document_POST, rObj, {}, (response) => {
                    if (response.errorCode === NO_ERROR) {
                        handleClearForm();
                        setShowKYCDocsSubmitSuccess(true);
                        setLoading(null);
                    } else {
                        toast.error(response.message, { id: "uploadKyc" });
                        setLoading(null);
                    }
                });
            }
        }
    };

    const handlePanCardImageSelection = (fileEvent) => {
        const { files } = fileEvent.target;
        const blob = new Blob([files], { type: files.type });
        const imgUrl = URL.createObjectURL(blob);
        setPanCardImageURL(imgUrl);
        const formData = new FormData();
        formData.append("file", files[0]);
        // handleUploadKYCImage(formData);
    };

    const handleSubmitUploadPANDocument = () => {
        setLoading("uploading");
        if (checkConditionsForPanSubmission()) {
            if (panFrontImage.imageURL) {
                const rObj = {
                    frontImageUrl: panFrontImage.imageURL,
                    backImageUrl: "",
                    documentNumber: KYCDocNumber,
                    documentType: "PanCard",
                };
                callPostApi(Upload_KYC_Document_POST, rObj, {}, (response) => {
                    if (response.errorCode === NO_ERROR) {
                        handleClearForm();
                        setShowKYCDocsSubmitSuccess(true);
                        setLoading(null);
                    } else {
                        toast.error(response.message, { id: "uploadKyc" });
                        setLoading(null);
                    }
                });
            }
        }
    };
    const handleKYCDocNumberValidation = (e) => {
        const { value, type } = e.target;
        if (uploadKYCDocFor === staticConstants.PAN_CARD_) {
            if (value.length > 0) {
                if (isSpecialChar(value)) {
                    return;
                }
            }
            if (!isPanCard(value) && value.length > 10) {
                return;
            }
        } else {
            if (value.length > 0) {
                if (!isNumeric(value)) {
                    return;
                }
            }
            if (value.length > 12) {
                return;
            }
        }
        setKYCDocNumber(value.toUpperCase());
    };

    const handleUploadKYC = (docType) => {
        const foundDocIdx = KYCDocumentList.findIndex((doc) => doc.documentType === docType);
        if (foundDocIdx > -1) {
            if (KYCDocumentList[foundDocIdx].verificationStatus === "None" || KYCDocumentList[foundDocIdx].verificationStatus === "Rejected") {
                setShowUploadFrontBackImageModal(true);
                // setShowUploadImageModal(true);
                setUploadKYCDocFor(docType);
            } else {
                return;
            }
        } else {
            setShowUploadFrontBackImageModal(true);
            // setShowUploadImageModal(true);
            setUploadKYCDocFor(docType);
        }
    };

    const handleExpandImage = (image) => {
        setSelectedImg(image);
        setShowEnlargedImage(true);
    };

    const handleHideEnlargedImage = () => {
        setShowEnlargedImage(false);
        setSelectedImg(null);
    };

    const getDocumentCard = (docType) => {
        const foundDocIdx = KYCDocumentList.findIndex((doc) => doc.documentType === docType);
        if (foundDocIdx > -1) {
            return KYCDocumentList[foundDocIdx].verificationStatus.toLowerCase() === "pending" || KYCDocumentList[foundDocIdx].verificationStatus.toLowerCase() === "approved" ? (
                <div>
                    <div className="d-flex align-items-center justify-content-between ">
                        {docType === staticConstants.PAN_CARD_ && (
                            <div className="d-block">
                                <h6 className="mb-0 font-size-14 font-weight-600 text-white">{staticConstants.PAN_CARD}</h6>
                            </div>
                        )}
                        {docType === staticConstants.AADHAR_CARD_ && (
                            <div className="d-block">
                                <h6 className="mb-0 font-size-14 font-weight-600 text-white">{staticConstants.AADHAAR}</h6>
                                {/* <p className="mb-0 font-size-12 text-light">{staticConstants.TAKES_THIRTY_MINUTES_FOR_VERIFICATION}</p> */}
                            </div>
                        )}
                        <div className="docNumber text-white text-center fw-bold">{KYCDocumentList[foundDocIdx].documentNumber}</div>
                        <p
                            className={`font-size-10 badge py-1 px-2 mb-0  ${
                                authState.userDetails?.kycVerificationStatus === "Approved"
                                    ? "badge-success"
                                    : authState.userDetails?.kycVerificationStatus === "Rejected"
                                    ? "badge-danger"
                                    : "badge-warning"
                            }`}
                        >
                            {authState.userDetails?.kycVerificationStatus}
                        </p>
                    </div>
                </div>
            ) : (
                <div>
                    <p className={`badge text-end ${KYCDocumentList[foundDocIdx].verificationStatus.toLowerCase() === "rejected" ? "badge-danger" : "badge-warning"}`}>
                        {KYCDocumentList[foundDocIdx].verificationStatus}
                    </p>

                    <div className="d-flex align-items-center justify-content-between ">
                        <div className="d-flex align-items-center">
                            <div className="circle-modal mr-2">
                                <img src="assets/img/pancard_icon.png" alt="pancard" className="w-100" />
                            </div>
                            <div className="mb-0 text-white">
                                <p className="mb-0">{insertSpaceInKeyDirect(docType)}</p>
                                <p className="text-red font-weight-400 mb-0 position-absolute">*{insertSpaceInKeyDirect(docType)} is mandatory.</p>
                            </div>
                        </div>
                        <span>
                            <img src="assets/img/kyc-arrow.png" alt="kyc arrow" style={{ width: "20px" }} />
                        </span>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="d-flex align-items-center justify-content-between ">
                    <div className="d-flex align-items-center">
                        <div className="circle-modal mr-2">
                            <img src="assets/img/pancard_icon.png" alt="pancard" className="w-100" />
                        </div>
                        <div className="mb-0 text-white">
                            <p className="mb-0">{insertSpaceInKeyDirect(docType)}</p>
                            <p className="text-red font-weight-400 mb-0 position-absolute">*{insertSpaceInKeyDirect(docType)} is mandatory.</p>
                        </div>
                    </div>
                    <span>
                        <img src="assets/img/kyc-arrow.png" alt="kyc arrow" style={{ width: "20px" }} />
                    </span>
                </div>
            );
        }
    };

    const scrollToView = (type) => {
        if (type === "panCardRef") {
            return panCardRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
        // else if (type === "bankNameRef") {
        //     bankNameRef?.current?.scrollIntoView({ behavior: "smooth" });
        // }
    };

    return (
        <>
            <KYCStatusCard handleShowCompleteKYCModal={() => setShowCompleteKYCModal(true)} />
            {showCompleteKYCModal && (
                // This is the modal which shows the main content of the KYC module
                <ModalBox show={showCompleteKYCModal} toggleModel={() => setShowCompleteKYCModal(false)} modalClass={"sliderBlockModal"}>
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body pt-2">
                            <div className=" modal-body-header">
                                <button type="button" className="btn-close" onClick={() => setShowCompleteKYCModal(false)}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/modal-close.png" alt="modalCloseImg" style={{ width: "25px" }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                    {staticConstants.SELECT_A_PHOTO_TO_PROCEED}
                                </h5>
                            </div>

                            <div className="mt-2">
                                {[staticConstants?.PAN_CARD_, staticConstants?.AADHAR_CARD_].map((docType, i) => (
                                    <div key={i} className="py-3 px-2 cursor-pointer border-top" onClick={() => handleUploadKYC(docType)}>
                                        <div className="position-relative">
                                            <div className="d-flex align-items-center" style={{ width: "140px" }}></div>
                                            {KYCDocumentList && KYCDocumentList.length > 0 ? (
                                                getDocumentCard(docType)
                                            ) : (
                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <div className="d-flex align-items-center">
                                                        <div className="circle-modal mr-2">
                                                            <img src="assets/img/pancard_icon.png" alt="pancard" className="w-100" />
                                                        </div>
                                                        <div className="mb-0 text-white">
                                                            <p className=" mb-0">{insertSpaceInKeyDirect(docType)}</p>
                                                            <p className="text-red font-weight-400 mb-0 position-absolute">*{insertSpaceInKeyDirect(docType)} is mandatory.</p>
                                                        </div>
                                                    </div>
                                                    <span>
                                                        <img
                                                            src="assets/img/kyc-arrow.png"
                                                            alt="kyc arrow"
                                                            style={{
                                                                width: "20px",
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        {/* {KYCDocumentList[i]?.verificationStatus.toLowerCase() === "rejected" && (
                                            <div>
                                                <p className="mb-0 font-size-12 text-white fw-bold">
                                                    Why Your{" "}
                                                    {insertSpaceInKeyDirect(docType)}{" "} is rejected ?
                                                </p>
                                                <p className="mb-0 font-size-12 text-white fw-bold">
                                                    {
                                                        KYCDocumentList[i]
                                                            .rejectionNote
                                                    }
                                                </p>
                                            </div>
                                        )} */}
                                    </div>
                                ))}
                            </div>
                            {authState.userDetails?.kycVerificationStatus === "Approved" ? (
                                <div></div>
                            ) : authState.userDetails?.kycVerificationStatus === "Rejected" ? (
                                <div className="py-3 px-2 border-top text-red">Note :- KYC is Rejected.</div>
                            ) : authState.userDetails?.kycVerificationStatus === "Rejected" ? (
                                <div className="py-3 px-2 border-top text-warning">Note :- KYC under review.</div>
                            ) : (
                                <div className="py-3 px-2 border-top text-red">Note :- Pan card and Aadhar card both are mandatory.</div>
                            )}
                        </div>
                    </div>
                </ModalBox>
            )}
            {/* This modal comes after the success of document upload */}
            {/* {showKYCDocsSubmitSuccess && (
                <ModalBox
                    show={showKYCDocsSubmitSuccess}
                    toggleModel={() => setShowKYCDocsSubmitSuccess(false)}
                    modalClass={"sliderBlockModal"}
                >
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body pb-0 pt-2">
                            <div className="text-right py-2">
                                <span
                                    onClick={() =>
                                        setShowKYCDocsSubmitSuccess(false)
                                    }
                                >
                                    <img
                                        src="assets/img/modal-close.png"
                                        alt=""
                                        style={{ width: "25px" }}
                                    />
                                </span>
                            </div>
                            <div className="py-4 text-center">
                                <img
                                    src="assets/img/deposit_success.png"
                                    alt="withdraw"
                                    style={{ width: "20%" }}
                                />
                                <h5 className="mb-0 text-green mt-3 font-size-12">
                                    {staticConstants.KYC_DOCUMENT_SUBMITTED}
                                </h5>
                                <p className="m-0 font-size-10 text-white">
                                    {
                                        staticConstants.YOUR_KYC_DOCUMENT_ARE_UNDER_REVIEW
                                    }
                                </p>
                            </div>
                            <div className="customised-btn-purple text-center pb-4">
                                <button
                                    onClick={() => {
                                        navigate("/wallet");
                                        setShowKYCDocsSubmitSuccess(false);
                                    }}
                                    className="btn text-white"
                                >
                                    {staticConstants.BACK_TO_WALLET}
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )} */}
            {showUploadFrontBackImageModal && (
                // This pops up the document upload dialog (aadhar or pan)
                <ModalBox show={showUploadFrontBackImageModal} toggleModel={() => setShowUploadFrontBackImageModal(false)} modalClass={"sliderBlockModal uploadFrontBackModal"}>
                    <div className="modal-content bg-yellowGradient border-none" style={{ overflowY: "auto", maxHeight: "100vh" }} id="root-12">
                        <div className="modal-body pt-2 text-center">
                            <div className=" modal-body-header">
                                <button type="button" className="btn-close" onClick={() => setShowUploadFrontBackImageModal(false)}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/modal-close.png" alt="" style={{ width: "15px" }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                    {uploadKYCDocFor === staticConstants.AADHAR_CARD_ ? staticConstants.UPLOAD_FRONT_AND_BACK_OF_AADHAAR : staticConstants.UPLOAD_FRONT_SIDE_OF_PAN_CARD}
                                </h5>
                            </div>
                            {uploadKYCDocFor === staticConstants.AADHAR_CARD_ ? (
                                // Here is the condition for showing the data for the aadhar card modal
                                <div className="py-2">
                                    <div className="row">
                                        <div className="col-6 padding-col">
                                            <input className="d-none" type="file" onChange={handleImageSelection} name="userProfile" id="fileElement" ref={fileRefAadharFront} accept="image/*" />
                                            <div className="preview_img cursor-pointer">
                                                <div className="main_img" onClick={() => handleGalleryOrCameraSelection("aadharFront")}>
                                                    <img
                                                        disabled={!loading}
                                                        src={aadharFrontImage === "" ? "assets/img/pancard.png" : aadharFrontImage}
                                                        alt="Aadhar Front Image"
                                                        id="preview"
                                                        className="preview"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3 mb-2 custom-green-btn text-white front-respnsv" onClick={() => handleGalleryOrCameraSelection("aadharFront")}>
                                                Upload Front
                                            </div>
                                        </div>
                                        <div className="col-6 padding-col">
                                            <input className="d-none" type="file" accept="image/*" onChange={handleImageSelection} name="userProfile" id="fileElement" ref={fileRefAadharBack} />
                                            <div className="preview_img cursor-pointer">
                                                <div className="main_img" onClick={() => handleGalleryOrCameraSelection("aadharBack")}>
                                                    <img
                                                        disabled={!loading}
                                                        src={aadharBackImage === "" ? "assets/img/pancard.png" : aadharBackImage}
                                                        alt="Aadhar Back Image"
                                                        id="preview"
                                                        className="preview"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-3 mb-2 custom-green-btn text-white front-respnsv" onClick={() => handleGalleryOrCameraSelection("aadharBack")}>
                                                Upload Back
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="py-2">
                                    <input className="d-none" type="file" accept="image/*" onChange={handleImageSelection} name="userProfile" id="fileElement" ref={fileRefPANFront} />
                                    <div className="text-center cursor-pointer">
                                        <div className="preview_img" onClick={() => handleGalleryOrCameraSelection("panFront")}>
                                            <div className="main_img">
                                                <img src={panFrontImage === "" ? "assets/img/pancard.png" : panFrontImage} disabled={!loading} alt="PAN Image" id="preview" className="preview" />
                                            </div>
                                        </div>
                                        {panFrontImage.imageURL && (
                                            <div onClick={() => handleGalleryOrCameraSelection("panFront")}>
                                                <label className="resend-decoration text-white font-size-10 font-weight-400 cursor-pointer">{staticConstants.REUPLOAD_PAN_CARD}</label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="pt-2 pb-3">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="kycDocCheck"
                                        checked={termsConditionsChecked}
                                        onChange={() => setTermsConditionsChecked(!termsConditionsChecked)}
                                    />
                                    <input type="checkbox" className="custom-control-input" id="kycDocCheck" />
                                    <label className="custom-control-label text-light" htmlFor="kycDocCheck">
                                        {staticConstants.TERMS_AND_CONDITIONS_TEXT}
                                        <button className="text-white ml-2 underline-btn" onClick={() => setShowTermsConditionsModal(true)}>
                                            {staticConstants.TERMS_AND_CONDITIONS}
                                        </button>
                                    </label>
                                </div>
                            </div>
                            {uploadKYCDocFor === staticConstants.PAN_CARD_ ? (
                                panFrontImage && termsConditionsChecked ? (
                                    <div className="pt-4 pb-3 custom-green-btn">
                                        <button className="btn w-100 padding-custom-green" onClick={handleUploadPanImage}>
                                            Upload
                                        </button>
                                    </div>
                                ) : (
                                    <div className="py-3 customised-btn-purple">
                                        <button className="btn w-100">Upload</button>
                                    </div>
                                )
                            ) : aadharFrontImage && aadharBackImage && termsConditionsChecked ? (
                                <div className="pt-4 pb-3 custom-green-btn">
                                    <button className="btn w-100 padding-custom-green" onClick={handleUploadAadharImage}>
                                        Upload
                                    </button>
                                </div>
                            ) : (
                                <div className="py-3 customised-btn-purple">
                                    <button className="btn w-100">Upload</button>
                                </div>
                            )}
                            <div style={{ height: "150px" }}></div>
                        </div>
                        {loader && (
                            <div className="custom-loader">
                                <Loader />
                            </div>
                        )}
                    </div>
                </ModalBox>
            )}
            {showEnlargedImage && (
                <ModalBox show={showEnlargedImage} toggleModel={handleHideEnlargedImage} modalClass={"enlargeImageModal"}>
                    <div className="modal-content bg-yellowGradient border-none" id="root-12">
                        <div className="modal-body text-center">
                            <div className="text-right py-2">
                                <span onClick={handleHideEnlargedImage}>
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "15px" }} />
                                </span>
                            </div>
                            <img src={selectedImg} alt="" />
                        </div>
                    </div>
                </ModalBox>
            )}

            {showTermsConditionsModal && (
                <ModalBox show={showTermsConditionsModal} toggleModel={() => setShowTermsConditionsModal(false)} modalClass={"sliderBlockModal termsAndConditionsModal"}>
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body pb-0 pt-2">
                            <div className="d-flex justify-content-center">
                                <div className="line-custom"></div>
                            </div>
                            <div className="d-flex justify-content-end">
                                <span type="button" className="btn-close" onClick={() => setShowTermsConditionsModal(false)}>
                                    <span className="cross-yellow" aria-hidden="true">
                                        <img src="assets/img/cross_icon.png" alt="cross" />
                                    </span>
                                </span>
                            </div>
                            <div className="staticPageText">
                                <h6 className="text-capitalize mb-4 mt-4 text-center font-weight-bold border-bottom pb-3">{staticConstants.TERMS_AND_CONDITIONS}</h6>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: termsAndCondition,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )}
        </>
    );
};

export default Kyc;
