export const convertAmount = (amountTemp) => {
    if (amountTemp === null || amountTemp === undefined) {
        amountTemp = 0;
    }
    const amount = Number(amountTemp);
    if (amount >= 1000000000000000) {
        // Quadrillion
        return (amount / 1000000000000000).toFixed(2) + "Q";
    } else if (amount >= 1000000000000) {
        // Trillion
        return (amount / 1000000000000).toFixed(2) + "T";
    } else if (amount >= 1000000000) {
        // Billion
        return (amount / 1000000000).toFixed(2) + "B";
    } else if (amount >= 1000000) {
        // Million  (  1 BTC = 100,000,000 Satoshi  > 100M  )
        return (amount / 1000000).toFixed(2) + "M";
    } else if (amount >= 1000) {
        // Thousand
        return (amount / 1000).toFixed(3) + "K";
    } else {
        return amount.toFixed(2);
    }
};
