import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Get_LastKnownWithdrawAccountTypeDetail_GET, Wallet_Withdraw_Request_POST, Withdraw_Money_Request_Details_Get } from "../../api/ApiConst";
import { toast } from "react-hot-toast";
import { MULTIPLE_ERRORS, NO_ERROR } from "../../api/constants";
import { callGetApi, callPostApi } from "../../api/ApiCaller";
import * as staticConstants from "../../constants/staticConstants";
import Select from "react-select";
import ModalBox from "../common/Modal";
import Kyc from "../account/kyc/Kyc";
import { GlobalContext } from "../../App";
import WithdrawableList from "../WithdrawableList";
import { convertAmount } from "../../utils/CurrencyUtils";
import Loader from "../common/Loader";
import { BUTTON_DISABLE, BUTTON_ENABLE } from "../../reducer/actions/utilityActions";
import Wrapper from "components/Wrapper";
import { useNavigate } from "react-router-dom";
import { Chain } from "components/common/SvgIcon";
import { NumberFormat } from "utils/IntlUtilities";
import CustomHeader from "components/common/CustomHeader";
// import KYCStatusCard from "components/account/kyc/KYCStatusCard0";

const transferTypes = [
    {
        value: "NEFT",
        label: "NEFT",
    },
    // {
    //     value: "IMPS",
    //     label: "IMPS",
    // },
    // {
    //     value: "RTGS",
    //     label: "RTGS",
    // },
];

const Withdraw = () => {
    const { authState, utilityState, dispatchUtility } = useContext(GlobalContext);
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const [withdrawalTransactionDetail, setWithdrawalTransactionDetail] = useState(0);
    const [showWithdrawalConfirmationModal, setShowWithdrawalConfirmationModal] = useState(false);
    const [showProcessingWithdrawRequest, setShowProcessingWithdrawRequest] = useState(false);
    const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
    const [selectedAccountType, setSelectedAccountType] = useState(staticConstants.BANK_ACCOUNT_);
    const [showWithdrawableList, setShowWithdrawableList] = useState(false);
    const [selectedTransfer, setSelectedTransferType] = useState({
        value: transferTypes[0].value,
        label: transferTypes[0].label,
    });
    const navigate = useNavigate();
    //const [disabledBtn, setDisabledBtn] = useState(false);
    const defaultBankDetails = {
        accountHolderName: "",
        accountNumber: "",
        accountType: "",
        amount: 0,
        bankName: "",
        cryptoWalletAddress: "",
        ifscCode: "",
        payTmMobileNumber: "",
        transferType: "",
        upiId: "",
    };
    const [bankingDetails, setBankingDetails] = useState(defaultBankDetails);
    const withdrawNetbankingRef = useRef(null);
    const bankNameRef = useRef(null);
    const accountHolderNameRef = useRef(null);
    const accountNumberRef = useRef(null);
    const confirmAccountNumberRef = useRef(null);
    const ifscCodeRef = useRef(null);
    const transferTypeRef = useRef(null);
    const [withdrawalOptions, setWithdrawalOptions] = useState(false);
    const [refreshFlagCounter, setRefreshFlagCounter] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [withdrawableBalance, setWithdrawableBalance] = useState(utilityState.walletDetails?.withdrawableBalance);

    const [validAccountFlag, setValidAccountFlag] = useState(false);

    const handleRefresh = useCallback(() => {
        setRefreshFlagCounter((prev) => prev + 1);
    }, [refreshFlagCounter]);

    useEffect(() => {
      setWithdrawableBalance(utilityState.walletDetails?.withdrawableBalance);
    }, [utilityState])
    

    useEffect(() => {
        getPreFilledData();
    }, [selectedAccountType]);

    const getPreFilledData = () => {
        callGetApi(Get_LastKnownWithdrawAccountTypeDetail_GET.replace("{accountType}", selectedAccountType), {}, (response) => {
            if (response.errorCode === 0) {
                setBankingDetails(response.responsePacket);
                setValidAccountFlag(response.responsePacket.valid);
                if (response.responsePacket.accountType === "BankAccount") {
                    setConfirmAccountNumber(response.responsePacket.accountNumber);
                }
            }
        });
    };

    const accountNumberValidation = () => {
        var regex = /^[0-9]{9,18}$/;
        if (!regex.test(bankingDetails.accountNumber)) {
            return false;
        }
        return true;
    };

    const IFSCValidation = () => {
        if (bankingDetails.ifscCode.length !== 11) {
            return false;
        }
        // var regex = /^[A-Za-z]{4}\d{7}$/;
        // if (!regex.test(bankingDetails.ifscCode)) {
        //     return false;
        // }
        return true;
    };

    const handleProceedToWithdraw = () => {
        if (!withdrawAmount) {
            toast(`Please enter some amount`, { id: "invalidWithdrawDetails" });
            return;
        } else if (withdrawAmount > withdrawableBalance) {
            toast(`Amount exceeded than Withdrawable amount ${withdrawableBalance}`, { id: "invalidWithdrawDetails" });
            return;
        } else if (!selectedAccountType || selectedAccountType === "") {
            toast(`Please select Account Type`, { id: "invalidWithdrawDetails" });
            return;
        }
        if (selectedAccountType === staticConstants.BANK_ACCOUNT_) {
            if (bankingDetails.bankName === "") {
                toast(`Please Enter Bank Name`, { id: "invalidWithdrawDetails" });
                return;
            } else if (bankingDetails.accountHolderName === "") {
                toast(`Please Enter Account Holder Name`, {
                    id: "invalidWithdrawDetails",
                });
                return;
            } else if (bankingDetails.accountNumber === "") {
                toast(`Please Enter Account Number`, { id: "invalidWithdrawDetails" });
                return;
            } else if (confirmAccountNumber === "" || confirmAccountNumber !== bankingDetails.accountNumber) {
                toast(`Account numbers do not match`, { id: "invalidWithdrawDetails" });
                return;
            } else if (bankingDetails.ifscCode === "") {
                toast(`Please Enter IFSC Code`, { id: "invalidWithdrawDetails" });
                return;
            } else if (!selectedTransfer.value || selectedTransfer.value === "") {
                toast(`Please select Transfer Type`, { id: "invalidWithdrawDetails" });
                return;
            } else if (!accountNumberValidation()) {
                toast("Please Enter Valid Account Number", {
                    id: "invalidWithdrawDetails",
                });
                return;
            } else if (!IFSCValidation()) {
                toast("Please Enter Valid IFSC Code", { id: "invalidWithdrawDetails" });
                return;
            }
        } else if (selectedAccountType === staticConstants.UPI_ID_) {
            if (bankingDetails.upiId === "") {
                toast(`Please Enter UPI ID`, { id: "invalidWithdrawDetails" });
                return;
            }
        }
        handleWithdraw();

    };

    const handleWithdraw = () => {
        //setDisabledBtn(true);
        const rObj = {
            accountHolderName: "",
            accountNumber: "",
            accountType: selectedAccountType,
            amount: withdrawAmount,
            bankName: "",
            cryptoWalletAddress: "",
            ifscCode: "",
            payTmMobileNumber: "",
            transferType: "",
            upiId: "",
        };

        if (selectedAccountType === staticConstants.BANK_ACCOUNT_) {
            rObj.accountNumber = bankingDetails.accountNumber;
            rObj.accountHolderName = bankingDetails.accountHolderName;
            rObj.bankName = bankingDetails.bankName;
            rObj.ifscCode = bankingDetails.ifscCode;
            rObj.transferType = selectedTransfer.value;
        } else if (selectedAccountType === staticConstants.UPI_ID_) {
            rObj.upiId = bankingDetails.upiId;
            rObj.transferType = "UPI";
        }
            setDisabledBtn(true);
            callPostApi(Wallet_Withdraw_Request_POST, rObj, {}, (response) => {

            if (response.errorCode === NO_ERROR) {
                setWithdrawAmount("");
                // getWalletDetails();
                // setBankingDetails(defaultBankDetails);
                // setShowWithdrawalConfirmationModal(false);
                // setShowProcessingWithdrawRequest(true);
                // setConfirmAccountNumber("");
                // // getSuccessWithdrawalDetails(response.responsePacket);
                // getPreFilledData();
                handleRefresh();
                setDisabledBtn(false);
                // // setLoading(false);
                // dispatchUtility({ type: BUTTON_ENABLE });
                setWithdrawableBalance(withdrawableBalance - withdrawAmount);
                toast.success(response.message, { id: "walletWithdrawRequest" });
            } else if (response.errorCode === MULTIPLE_ERRORS) {
                setDisabledBtn(false);
                // setLoading(false);
                // dispatchUtility({ type: BUTTON_ENABLE });
                toast.error(
                    response.responsePacket.map((data) => <div>{data}</div>),
                    { id: "walletWithdrawRequest" }
                );
            } else {
                setDisabledBtn(false);
                // setLoading(false);
                // dispatchUtility({ type: BUTTON_ENABLE });
                toast.error(response.message, { id: "walletWithdrawRequest" });
            }
        });
    };

    // const getSuccessWithdrawalDetails = (withdrawalTransactionID) => {
    //     let url = Withdraw_Money_Request_Details_Get.replaceAll("{withdrawRequestId}", withdrawalTransactionID);
    //     callGetApi(url, {}, (response) => {
    //         if (response.errorCode === NO_ERROR) {
    //             setWithdrawalTransactionDetail(response.responsePacket);
    //         } else {
    //             toast.error(response.message, { id: "withdrawMoneyDetails" });
    //         }
    //     });
    // };

    const handleChangeNetBankingDetails = (e) => {
        const { value, name } = e.target;

        if (name === "accountNumber") {
            if (value.length > 16) {
                return;
            }
        }
        if (name === "ifscCode") {
            if (value.length > 11) {
                return;
            }
        }

        setBankingDetails({
            ...bankingDetails,
            [e.target.name]: value,
        });
    };

    const scrollToView = (type) => {
        if (type === "withdrawNetbankingRef") {
            return withdrawNetbankingRef?.current?.scrollIntoView({
                behavior: "smooth",
            });
        } else if (type === "bankNameRef") {
            return bankNameRef?.current?.scrollIntoView({ behavior: "smooth" });
        } else if (type === "accountHolderNameRef") {
            return accountHolderNameRef?.current?.scrollIntoView({
                behavior: "smooth",
            });
        } else if (type === "accountNumberRef") {
            return accountNumberRef?.current?.scrollIntoView({ behavior: "smooth" });
        } else if (type === "confirmAccountNumberRef") {
            return confirmAccountNumberRef?.current?.scrollIntoView({
                behavior: "smooth",
            });
        } else if (type === "ifscCodeRef") {
            return ifscCodeRef?.current?.scrollIntoView({ behavior: "smooth" });
        } else if (type === "transferTypeRef") {
            return transferTypeRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
    };
    const [bankAccountType, setBankAccountType] = useState("");
    const onSelectWithdrawBanking = () => {
        setBankAccountType(staticConstants.BANK_ACCOUNT_);
    };

    const handleAmountChange = (e) => {
        const value = parseFloat(e.target.value);
        const regex = /^(?!-0$)-?\d+(?:[.,]\d+)?$/;
        if (value === "" || regex.test(value)) {
            setWithdrawAmount(value);
        }
    };

    const handleResetBtn = () => {
        setWithdrawAmount("");
        setBankingDetails(defaultBankDetails);
        setConfirmAccountNumber("");
    }

    return (
        <>
            <Wrapper>
                <div>
                    <CustomHeader title={"Withdraw"} />
                    <div className="WithdrawContent pb-0 px-3 pt-3">
                        <div className="AmountCard">
                            <div className="innerBox">
                                <h6 className="font-size-16 font-weight-600" style={{ color: "#6F571D" }}>
                                    Withdrawable Amount
                                </h6>
                                <h4 className="text-black font-size-24 font-weight-700">₹{NumberFormat(withdrawableBalance || 0)}</h4>
                            </div>
                        </div>
                        <div className=" position-relative">
                            <span className="leftChain">
                                <Chain />
                            </span>
                            <span className="rightChain">
                                <Chain />
                            </span>
                            <div className="Withdraw-yellow-card p-3 mt-3">
                                <div className="form-group mb-0">
                                    <label className="font-size-14 text-black mb-3">Enter Amount</label>
                                    <input
                                        type="text"
                                        className="form-control text-black"
                                        name="amount"
                                        placeholder="Enter your amount"
                                        value={withdrawAmount}
                                        onChange={(e) => handleAmountChange(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="customHr my-4"></div>
                        {/* <div className="yellowCard mb-3">
                            <div className="innerCard py-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span className="iconCard">
                                            <img src="assets/img/upiIcon.png" alt="upiIcon" />
                                        </span>
                                        <div className="ml-2">
                                            <p className="mb-0 text-white font-weight-600">UPI ID</p>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="linkBtn">Link</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="yellowCard mb-3">
                            <div className="innerCard py-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span className="iconCard">
                                            <img src="assets/img/DepositsIcon.png" alt="DepositsIcon" />
                                        </span>
                                        <div className="ml-2">
                                            <p className="mb-0 text-white font-weight-600">Bank Account</p>
                                        </div>
                                    </div>
                                    <div>
                                        <button className="linkBtn">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="customHr mt-4 mb-2"></div> */}
                        {/* <div className="selectPlayerBox selectAmount">
                            <div className="selectPlayer d-flex justify-content-between align-items-center ">
                                <div className="rdio rdio-primary radio-inline">
                                    {" "}
                                    <input name="radio" id="radio1" type="radio" checked={withdrawalOptions} onChange={() => setWithdrawalOptions(true)} />
                                    <label htmlFor="radio1">
                                        <span className="mr-2" style={{ width: "18px", display: "inline-block" }}>
                                            <img src="assets/img/upiIcon.png" alt="upiIcon" />
                                        </span>
                                        UPI
                                    </label>
                                </div>
                                <div className="rdio rdio-primary radio-inline">
                                    <input name="radio" checked={!withdrawalOptions} id="radio2" type="radio" onChange={() => setWithdrawalOptions(false)} />
                                    <label htmlFor="radio2">
                                        <span className="d-flex align-items-center ">
                                            <span className="mr-2" style={{ width: "18px", display: "flex" }}>
                                                <img src="assets/img/DepositsIcon.png" alt="DepositsIcon" />
                                            </span>
                                            Net Banking
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="SelectUpi mb-5 pb-5" ref={transferTypeRef} onClick={() => scrollToView("transferTypeRef")}>
                            <div className="form-group">
                                <Select
                                    className="customSelect"
                                    isSearchable={false}
                                    options={transferTypes}
                                    value={selectedTransfer}
                                    isDisabled={validAccountFlag}
                                    onChange={(e) => setSelectedTransferType(e)}
                                />
                            </div>
                        </div> */}
                        <div className="Withdraw-yellow-card p-3 mb-5 mt-3">
                            <div className="" ref={bankNameRef} onClick={() => scrollToView("bankNameRef")}>
                                <div className="form-group">
                                    <label className="font-size-14 text-black">{staticConstants.BANK_NAME}</label>

                                    <input
                                        type="text"
                                        className="form-control input-text-white"
                                        name="bankName"
                                        value={bankingDetails.bankName}
                                        disabled={validAccountFlag}
                                        onChange={(e) => {
                                            /^[a-zA-Z_ ]*$/.test(e.target.value) && handleChangeNetBankingDetails(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="" ref={accountHolderNameRef} onClick={() => scrollToView("accountHolderNameRef")}>
                                <div className="form-group">
                                    <label className="font-size-14 text-black">{staticConstants.ACCOUNT_HOLDER_NAME}</label>
                                    <input
                                        type="text"
                                        className="form-control input-text-white"
                                        name="accountHolderName"
                                        value={bankingDetails.accountHolderName}
                                        disabled={validAccountFlag}
                                        onChange={(e) => {
                                            /^[a-zA-Z_ ]*$/.test(e.target.value) && handleChangeNetBankingDetails(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="" ref={accountNumberRef} onClick={() => scrollToView("accountNumberRef")}>
                                <div className="form-group">
                                    <label className="font-size-14 text-black">{staticConstants.ACCOUNT_NUMBER}</label>
                                    <input
                                        type="text"
                                        className="form-control input-text-white"
                                        name="accountNumber"
                                        value={bankingDetails.accountNumber}
                                        disabled={validAccountFlag}
                                        onChange={(e) => {
                                            /^[0-9]*$/.test(e.target.value) && handleChangeNetBankingDetails(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="" ref={confirmAccountNumberRef} onClick={() => scrollToView("confirmAccountNumberRef")}>
                                <div className="form-group">
                                    <label className="font-size-14 text-black">{staticConstants.CONFIRM_ACCOUNT_NUMBER}</label>
                                    <input
                                        type="text"
                                        className="form-control input-text-white"
                                        name="accountNumber"
                                        value={confirmAccountNumber}
                                        disabled={validAccountFlag}
                                        onChange={(e) => /^[0-9]*$/.test(e.target.value) && setConfirmAccountNumber(e.target.value)}
                                    />
                                    {confirmAccountNumber !== "" && confirmAccountNumber !== bankingDetails.accountNumber && (
                                        <span className="text-danger font-size-12">Account Number doesn't match</span>
                                    )}
                                </div>
                            </div>
                            <div className="" ref={ifscCodeRef} onClick={() => scrollToView("ifscCodeRef")}>
                                <div className="form-group">
                                    <label className="font-size-14 text-black">{staticConstants.IFSC_CODE}</label>
                                    <input
                                        type="text"
                                        className="form-control input-text-white"
                                        name="ifscCode"
                                        value={bankingDetails.ifscCode}
                                        disabled={validAccountFlag}
                                        onChange={(e) => /^[a-zA-Z0-9]*$/.test(e.target.value) && handleChangeNetBankingDetails(e)}
                                    />
                                </div>
                            </div>
                            <div className="SelectUpi" ref={transferTypeRef} onClick={() => scrollToView("transferTypeRef")}>
                                <div className="form-group mb-0">
                                    <label className="font-size-14 text-black">{staticConstants.TRANSFER_TYPE}</label>
                                    <Select
                                        className="customSelect"
                                        isSearchable={false}
                                        options={transferTypes}
                                        value={selectedTransfer}
                                        isDisabled={validAccountFlag}
                                        onChange={(e) => setSelectedTransferType(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=" position-absolute  end-0 start-0 bottom-0 px-3 mb-3 d-flex" style={{gap: "20px"}}>
                            <button className="btn custom-yellow-btn w-100" disabled={disabledBtn} onClick={handleProceedToWithdraw}>Withdraw</button>
                            <button className="btn custom-yellow-btn w-100" disabled={disabledBtn} onClick={handleResetBtn}>Reset</button>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default Withdraw;
