import React from "react";
import { useNavigate } from "react-router-dom";

const CustomHeader = ({title}) => {

    const navigate = useNavigate();

    return (
        <div className="customHeader">
            <span className=" position-absolute" style={{left: "10px"}}>
                <img
                    src="assets/img/backIcon.png"
                    className="cursor-pointer"
                    alt="back left arrow"
                    style={{width: "50px"}}
                    onClick={() => navigate(-1)}
                />{" "}
            </span>
            <span className="headerTitle">{title}</span>
        </div>
    );
};

export default CustomHeader;
