import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import Slider from "react-slick";
import { NO_ERROR } from "../../api/constants";
import { Avatar_List_GET, Upload_File_POST } from "../../api/ApiConst";
import { callGetApi, callPostApi } from "../../api/ApiCaller";
import * as staticConstants from "../../constants/staticConstants";
import Loader from "./../common/Loader";

const Avatars = ({ userDetails, toggleEditAvatarModal, handleChangeAvatar, handleUpdateAvatar, loading }) => {
    const fileRef = useRef(null);
    const [profileData, setProfileData] = useState({
        fileName: "",
        fileBase64: "",
    });
    const [avatarList, setAvatarList] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(userDetails.profileImgUrl);
    const [loadingList, setLoadingList] = useState(null);

    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 4,
        // slidesToScroll: 1,
        swipeToSlide: true,
    };
    const handleProfileImageClick = (event) => {
        fileRef.current.click();
    };

    useEffect(() => {
        setLoadingList(true);
        callGetApi(Avatar_List_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                setAvatarList(response.responsePacket);
                setLoadingList(null);
            } else {
                toast.error(response.message, { id: "avatarList" });
            }
        });
    }, []);

    const handleSetUpdatedImage = (imageURL) => {
        setUpdatedImage(imageURL);
        setProfileData({
            fileName: "",
            fileBase64: "",
        });
    };

    const handleSetAvatar = () => {
        if (profileData.fileBase64) {
            callPostApi(Upload_File_POST, profileData, {}, (fileResponse) => {
                if (fileResponse.errorCode === NO_ERROR) {
                    setProfileData({ fileName: "", fileBase64: "" });
                    handleUpdateAvatar(fileResponse.responsePacket);
                    toast.success(fileResponse.message, { id: "updateFile" });
                } else {
                    toast.error(fileResponse.message, { id: "updateFile" });
                }
            });
        } else {
            handleUpdateAvatar(updatedImage);
        }
    };

    const handleProfileImageChange = (fileEvent) => {
        const { files } = fileEvent.target;
        if (files && files[0]) {
            var reader = new FileReader();
            reader.onload = function (event) {
                const { result } = event.target;
                fileRef.current.setAttribute("src", result);
                setProfileData({
                    fileName: files[0].name,
                    fileBase64: result,
                });
                handleChangeAvatar(result);
            };
            reader.readAsDataURL(files[0]);
        }
    };

    return (
        <>
            <div className="modal-content bg-yellowGradient border-none">
                <div className="modal-body p-0">
                    <div className=" modal-body-header">
                        <button type="button" className="btn-close" onClick={toggleEditAvatarModal}>
                            <span className="cursor-pointer">
                                <img src="assets/img/modal-close.png" alt="" style={{ width: "15px" }} />
                            </span>
                        </button>
                        <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                            {staticConstants.CHANGE_PROFILE_PICTURE}
                        </h5>
                    </div>
                    <div className="p-3">
                        <div className="yellowCard mb-3 ">
                            <div className="innerCard py-3">
                                <div className="my-3 d-flex align-items-center justify-content-center">
                                    <div className="avtaar-user">
                                        {loading ? <Loader /> : <img src={updatedImage ? updatedImage : "assets/img/avtaar-user.png"} alt="avtaar" className=" rounded-circle" />}
                                    </div>
                                </div>
                                <input className="d-none" type="file" onChange={handleProfileImageChange} name="userProfile" id="fileElement" ref={fileRef} />
                                {loadingList || !avatarList ? (
                                    <Loader />
                                ) : (
                                    <Slider {...settings}>
                                        {avatarList &&
                                            avatarList.length > 0 &&
                                            avatarList.map((avatar, i) => (
                                                <div className="item" key={i}>
                                                    <div className="item-box">
                                                        <div className="img-width cursor-pointer avatarImg" onClick={() => handleSetUpdatedImage(avatar.avatarImageUrl)}>
                                                            <img src={avatar.avatarImageUrl} alt={avatar.avatarName} className="mx-auto" />
                                                        </div>
                                                        <p className="text-center font-size-10 mt-2 mb-0 text-white font-weight-600">{avatar.avatarName}</p>
                                                    </div>
                                                </div>
                                            ))}
                                    </Slider>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-3">
                        <button className="linkBtn" onClick={handleSetAvatar}>
                            {staticConstants.SET}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Avatars;
