import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import { GlobalContext } from "../../App";
import FooterMenu from "../FooterMenu";
import * as staticConstants from "../../constants/staticConstants";
import AddCash from "./AddCash";
import Withdraw from "./Withdraw";
import History from "./History";
import { callGetApi } from "../../api/ApiCaller";
import { Refresh_Wallet_BALANCE_GET, Wallet_Balance_GET } from "../../api/ApiConst";
import { NO_ERROR } from "../../api/constants";
import { toast } from "react-hot-toast";
import { SET_ACTIVE_WALLET_TAB, SET_WALLET_DETAILS } from "../../reducer/actions/utilityActions";
import { useNavigate } from "react-router-dom";
import { convertAmount } from "../../utils/CurrencyUtils";
import { NotificationIcon, SettingIcon } from "components/common/SvgIcon";
import { NumberFormat } from "utils/IntlUtilities";

const Wallet = () => {
    const { authState, dispatchUtility, utilityState } = useContext(GlobalContext);
    const [walletDetails, setWalletDetails] = useState(null);
    const walletTabs = ["Add Cash", "Withdraw", "History"];

    useEffect(() => {
        setWalletDetails(utilityState.walletDetails);
    }, [utilityState.walletDetails]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!walletDetails) {
            getWalletDetails();
        }
    }, []);

    const getWalletDetails = () => {
        callGetApi(Wallet_Balance_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                setWalletDetails(response.responsePacket);
                dispatchUtility({ type: SET_WALLET_DETAILS, payload: response.responsePacket });
            } else {
                toast.error(response.message, { id: "getWalletBalance" });
            }
        });
    };

    const handleSelectTab = (tabName) => {
        dispatchUtility({ type: SET_ACTIVE_WALLET_TAB, payload: tabName });
    };

    const handleWhatsapp = () => {
        window.sendMessageToVuplex("UpdateShowUrl", `https://api.whatsapp.com/send?phone=+916299689386`);
    };

    const handleRefreshWallet = () => {
        callGetApi(Refresh_Wallet_BALANCE_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                getWalletDetails();
                toast.success(response.message, { id: "refreshWallet" });
            } else {
                toast.error(response.message, { id: "refreshWallet" });
            }
        });
    };
    return (
        <Wrapper>
            <div className="">
                <div className="walletHeader">
                    <div className="d-flex align-items-center justify-content-between ">
                        <h5>Wallet</h5>
                        <div>
                            <span className="cursor-pointer mr-2" onClick={() => navigate("/setting")}>
                                <SettingIcon />
                            </span>
                            <span className="cursor-pointer">
                                <NotificationIcon />
                            </span>
                        </div>
                    </div>
                    <div className="mt-5 d-flex justify-content-between align-items-center">
                        <div>
                            <h6 className="text-white font-weight-400 mb-0">Your Total Balance</h6>
                            <h3 className="my-0 text-white font-weight-600 font-size-35">{walletDetails && convertAmount(walletDetails.walletBalance)}</h3>
                        </div>
                        <div>
                            <button className="DepositBtn" onClick={handleRefreshWallet}><span>Refresh</span></button>
                        </div>
                    </div>
                </div>
                <div className="px-3 mt-3 walletContent">
                    {/* <div className="yellowCard mb-3">
                        <div className="innerCard py-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <span className="iconCard">
                                        <img src="assets/img/DepositsIcon.png" alt="DepositsIcon" />
                                    </span>
                                    <div className="ml-2">
                                        <small className="mb-0 text-yellow-light">Deposits</small>
                                    </div>
                                </div>
                                <div>
                                    <button className="DepositBtn" onClick={() => navigate("/add-cash")}>
                                        <span>Deposit</span>
                                    </button>
                                </div>
                            </div>
                            <div className="customHr my-3"></div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex">
                                    <span className="iconCard">
                                        <img src="assets/img/Winnings.png" alt="Winnings" />
                                    </span>
                                    <div className="ml-2">
                                        <small className="mb-0 text-yellow-light">Withdraw</small>
                                        <p className="mb-0 text-white font-weight-600">₹ {NumberFormat(utilityState.walletDetails?.withdrawableBalance)}</p>
                                    </div>
                                </div>
                                <div>
                                    <button className="WithdrawBtn" onClick={() => navigate("/withdraw")}>
                                        <span>Withdraw</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="yellowCard mb-3">
                        <div className="innerCard" onClick={() => navigate("/history")}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/TransactionIcon.png" alt="TransactionIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Transaction History</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="yellowCard mb-3">
                        <div className="innerCard">
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/TransactionIcon.png" alt="TransactionIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Deposits History</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="yellowCard mb-3">
                        <div className="innerCard">
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/TransactionIcon.png" alt="TransactionIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Withdraw History</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="yellowCard mb-3">
                        <div className="innerCard" onClick={handleWhatsapp}>
                            <div className="d-flex align-items-center">
                                <span className="iconCard">
                                    <img src="assets/img/whatsapp.png" alt="TDSIcon" />
                                </span>
                                <div className="ml-2">
                                    <p className="mb-0 text-white font-weight-600">Chat Support</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="custom-tabs d-none">
                    <div className="tabs-container">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            {walletTabs.map((tab, i) => (
                                <li className="nav-item" key={i}>
                                    <button
                                        onClick={() => handleSelectTab(tab)}
                                        className={`nav-link w-100 ${utilityState.activeWalletTab === tab ? "active" : ""}`}
                                        data-toggle="tab"
                                        href="#wallet-tab-content"
                                        role="tab"
                                    >
                                        {tab}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="wallet-tab-content" role="tabpanel" aria-labelledby="home-tab">
                            {/* {utilityState.activeWalletTab === walletTabs[0] && <AddCash />} */}
                            {/* {utilityState.activeWalletTab === walletTabs[1] && <Withdraw walletDetails={walletDetails} getWalletDetails={getWalletDetails} />} */}
                            {/* {utilityState.activeWalletTab === walletTabs[2] && <History />} */}
                        </div>
                    </div>
                </div>
            </div>
            <FooterMenu />
        </Wrapper>
    );
};

export default Wallet;
