import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { GlobalContext } from "../../App";
import { callGetApi, callGetApiWithoutAuth, callPostApi } from "../../api/ApiCaller";
import {
    Add_Cash_Transaction_List_POST,
    Generate_Payment_Transaction_POST,
    Get_Coupon_Banner_List,
    Get_Payment_Transaction_GET,
    Get_StoreProductList_GET,
    Pay_By_Card_POST,
    Pay_By_UPI_POST,
    Wallet_Balance_GET,
} from "../../api/ApiConst";
import { MULTIPLE_ERRORS, NO_ERROR } from "../../api/constants";
import * as staticConstants from "../../constants/staticConstants";
import { SET_WALLET_DETAILS } from "../../reducer/actions/utilityActions";
import { convertAmount } from "../../utils/CurrencyUtils";
import DateUtilities from "../../utils/DateUtilities";
import Loader from "../common/Loader";
import ModalBox from "../common/Modal";
import Slider from "react-slick";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Wrapper from "components/Wrapper";
import { Chain } from "components/common/SvgIcon";
import CustomHeader from "components/common/CustomHeader";

const AddCash = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const txnId = urlParams.get("txnId");

    const navigate = useNavigate();

    const { dispatchUtility } = useContext(GlobalContext);
    const [amount, setAmount] = useState(0);
    const [showAddCashModal, setShowAddCashModal] = useState(false);
    const payResponse = "";
    const [transactionHistoryList, setTransactionHistoryList] = useState(null);
    const [historyListDetails, setHistoryListDetails] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(null);
    const paymentResponse = null;
    const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
    const countDown = 0;
    const [showPendingPaymentModal, setShowPendingPaymentModal] = useState(false);
    const [showWebModalForCardPayment, setShowWebModalForCardPayment] = useState(false);
    const [showWebModal, setShowWebModal] = useState(false);
    const [currentTransactionDetail, setCurrentTransactionDetail] = useState(null);
    const [cardPaymentUrl, setCardPaymentUrl] = useState(null);
    const [UPIUrl, setUPIUrl] = useState(null);
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [storeProductList, setStoreProductList] = useState([]);
    const [bannerList, setBannerList] = useState([]);

    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    useEffect(() => {
        if (txnId) {
            callGetApi(Get_Payment_Transaction_GET.replaceAll("{paymentTransactionRefId}", txnId), {}, (response) => {
                const res = response.responsePacket;
                handlePendingPaymentModal(res);
            });
        }
        getStoreProductList();
        getCouponBannerList();
    }, []);

    const getCouponBannerList = () => {
        callGetApiWithoutAuth(Get_Coupon_Banner_List.replaceAll("{bannerPosition}", "CouponBanner"), (res) => {
            if (res.errorCode === 0) {
                setBannerList(res.responsePacket);
            }
        }, (err) => {
            console.error("Catch Error :: ", err);
        })
    }

    const getStoreProductList = () => {
        callGetApi(Get_StoreProductList_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                // var list = [];
                // for (let i = 0; i < response.responsePacket.length; i++) {
                //     list.push(response.responsePacket[i].productPrice);
                // }
                setStoreProductList(response.responsePacket);
            } else {
                toast.error(response.message, {
                    id: "getWalletDetails",
                });
            }
        });
    };

    useEffect(() => {
        setTransactionHistoryList(null);
        setCurrentPage(0);
        setHistoryListDetails(null);
        setLoading("preData");
        getList();
        getWalletDetails();
    }, []);

    useEffect(() => {
        setCurrentPage(0);
        setLoading("preData");
        getList();
        getWalletDetails();
    }, [showAddCashModal]);

    const getWalletDetails = () => {
        callGetApi(Wallet_Balance_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                dispatchUtility({
                    type: SET_WALLET_DETAILS,
                    payload: response.responsePacket,
                });
            } else {
                toast.error(response.message, { id: "walletDetails" });
            }
        });
    };

    const getList = (load = false) => {
        const rObj = {
            pageNumber: load ? currentPage + 1 : currentPage,
            pageSize: 10,
            search: "",
        };
        if (load) {
            setCurrentPage((prev) => prev + 1);
        }
        if (!loading) {
            setLoading("moreData");
        }
        callPostApi(Add_Cash_Transaction_List_POST, rObj, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                if (currentPage === 0 && !load) {
                    setTransactionHistoryList(response.responsePacket.data);
                } else {
                    const transactionHistoryListClone = [...transactionHistoryList, ...response.responsePacket.data];
                    setTransactionHistoryList(transactionHistoryListClone);
                }
                setHistoryListDetails({
                    totalItems: response.responsePacket.totalItems,
                });
                setLoading(null);
            } else {
                toast.error(response.message, { id: "addCashTransactionList" });
                setLoading(null);
            }
        });
    };

    const handleIncreaseAmountBy = (amt) => {
        if (amount === "") {
            setAmount(amt);
        } else {
            setAmount(parseInt(amount) + amt);
        }
    };

    const handleAddCash = () => {
        if (amount === "" || amount <= 0) {
            toast("Please Enter some amount", { id: "enterAmount" });
            return;
        }
        const rObj = {
            amount: amount,
        };
        // setLoading("addCash");
        callPostApi(Generate_Payment_Transaction_POST, rObj, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                setCurrentTransactionDetail(response.responsePacket);
                handlePayByUPI(response.responsePacket.paymentTransactionRefId);
                // handlePayByCard(response.responsePacket.paymentTransactionRefId);
                // setLoading(null);
                // // TO Open UPI Dialog
                // setShowPendingPaymentModal(true);
            } else if (response.errorCode === MULTIPLE_ERRORS) {
                toast.error(
                    response.responsePacket.map((data) => <div>{data}</div>),
                    {
                        id: "addCash",
                    }
                );
                // setLoading(null);
            } else {
                toast.error(response.message, {
                    id: "addCash",
                });
                // setLoading(null);
            }
        });
    };

    const handlePayByUPI = (paymentTransactionRefId) => {
        setLoading(true);
        callPostApi(Pay_By_UPI_POST.replaceAll("{paymentTransactionRefId}", paymentTransactionRefId), {}, {}, (response) => {
            // callPostApi(Pay_By_UPI_POST.replaceAll("{paymentTransactionRefId}", currentTransactionDetail.paymentTransactionRefId), {}, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                // window.sendMessageToVuplex("UpdateShowUri", response.responsePacket?.intentUrl);
                // // // To open UPI dialog.
                // setLoading("UPIUrl");
                // setUPIUrl(response.responsePacket?.intentUrl);
                // setShowPendingPaymentModal(true);
                // // // // setShowWebModal(true);
                // // To open direct url.
                // setLoading(null);
                setUPIUrl(response.responsePacket?.intentUrl);
                handlePayByOther(response.responsePacket?.intentUrl);
            } else {
                toast.error(response.message, { id: "payByUpi" });
                // setLoading(null);
            }
            setLoading(false);
        });
    };

    const handlePayByOther = (upiUrl) => {
        if (upiUrl !== undefined) {
            window.sendMessageToVuplex("UpdateShowUri", upiUrl);
        } else {
            window.sendMessageToVuplex("UpdateShowUri", UPIUrl);
        }
        setShowPendingPaymentModal(false);
        setShowWebModal(true);
        // setLoading(null);
    };

    const payByUpiOptions = (upiType) => {
        const upiUrlObj = upiType === "tez" ? decodeURIComponent(UPIUrl).replaceAll("upi://", upiType + "://upi/") : decodeURIComponent(UPIUrl).replaceAll("upi", upiType);
        window.sendMessageToVuplex("UpdateShowUri", upiUrlObj);
        setShowPendingPaymentModal(false);
        setShowWebModal(true);
        setLoading(null);
    };

    const handleShowPaymentStatusModal = () => setShowPaymentStatusModal(!showPaymentStatusModal);

    useEffect(() => {
        getList();
        getWalletDetails();
        // setUPIUrl(null);
    }, [showPendingPaymentModal, showWebModal, showWebModalForCardPayment]);

    const getCountdown = () => {
        const minutes = Math.floor(countDown / 60);
        const seconds = Math.floor(countDown % 60);
        return minutes + ":" + (seconds < 10 ? "0" + seconds : seconds);
    };

    const handlePayNow = () => {
        window.sendMessageToVuplex("UpdateShowUri", payResponse?.intentUrl);
    };

    const handleDownload = () => {
        toast.success("Image Downloaded Successfully!", { id: "imageDownload" });
        window.sendMessageToVuplex("UpdateDownloadUri", payResponse.qrData);
    };

    const handlePendingPaymentModal = (transaction) => {
        if (transaction.paymentStatus !== "Pending") {
            return;
        }
        setCurrentTransactionDetail(transaction);
        // setShowPendingPaymentModal(true);
        setShowWebModal(true);
    };

    const handlePayByCard = () => {
        callPostApi(Pay_By_Card_POST.replaceAll("{paymentTransactionRefId}", currentTransactionDetail.paymentTransactionRefId), {}, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                // window.sendMessageToVuplex("UpdateShowUri", response.responsePacket?.paymentUrl);
                // setPaymentInitiated(true);
                // setCountDown(300);
                setShowPendingPaymentModal(false);
                setCardPaymentUrl(response.responsePacket?.paymentUrl);
                setShowWebModalForCardPayment(true);
                // setShowWebModal(true);
                toast.success(response.message, { id: "payByCard" });
            } else {
                toast.error(response.message, { id: "payByCard" });
            }
        });
    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        const regex = /^(?!-0$)-?\d+(?:[.,]\d+)?$/;

        if (value === "" || regex.test(value)) {
            setAmount(value);
        }
    };

    return (
        <Wrapper>
            <div className="">
                <CustomHeader title={"Coin Store"} />
                {/* <div className="customHeader">
                    <span className=" position-absolute" style={{ left: "10px" }}>
                        <img src="assets/img/backIcon.png" className="cursor-pointer" alt="back left arrow" style={{ width: "50px" }} onClick={() => navigate(-1)} />{" "}
                    </span>
                    <span className="headerTitle">Coin Store</span>
                </div> */}
                {bannerList.length > 0 && <Slider {...settings}>
                    {bannerList.map((item) => (
                        <div key={item.recordId}>
                            <img src={item.bannerImageUrl} alt="homeSlider1" />
                        </div>
                    ))}
                </Slider>}
                <div className="DepositsListContent mt-0">
                    <div className="yellowCard">
                        <div className=" innerCard p-1" style={{ position: "unset" }}>
                            <div className="gameListInnerContent mt-0 ">
                                <div className="row m-0">
                                    {storeProductList.map((item) => (
                                        // <div key={item} className="gameListTail" onClick={() => navigate("/payment-method", { state: { amount: item.productPrice } })}>
                                        <div 
                                            key={item} 
                                            className="gameListTail" 
                                            onClick={() => {
                                                setSelectedAmount(item)
                                                setAmount(item.productPrice)
                                            }}
                                        >
                                            <div className={`deposit-list-card text-center d-flex flex-column justify-content-center align-items-center py-3 px-2 position-relative ${selectedAmount.productTitle === item.productTitle ? "selectedBox" : ""}`}>
                                                {/* <img src="assets/img/deposit_coins.png" alt="deposit_coins" style={{ width: "60%" }} /> */}
                                                <span className="text-black font-size-14 font-weight-600">Get</span>
                                                <span className="text-black font-size-18 font-weight-700 d-block">₹{Intl.NumberFormat("en-IN").format(item.chips)}</span>
                                                <div className="correctBox">
                                                    <img src="assets/img/radioCheck.png" alt="" />
                                                </div>
                                            </div>
                                            <div className=" position-relative px-2">
                                                <span className="leftChain">
                                                    <Chain />
                                                </span>
                                                <span className="rightChain">
                                                    <Chain />
                                                </span>
                                                <span className="deposit-list-btn">₹{Intl.NumberFormat("en-IN").format(item.productPrice)}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="addCashAmountBox">
                                    <div className="d-flex align-items-center justify-content-between ">
                                        <div>
                                            <h5 className="text-black font-weight-700 font-size-14 mb-0">Buy-In </h5>
                                            <h6 className="text-black font-weight-600 font-size-12 mb-0">Use to play games</h6>
                                        </div>
                                        <div className="d-flex">
                                            <h4 className="mb-0">₹{selectedAmount.productPrice ? Intl.NumberFormat("en-IN").format(selectedAmount.productPrice) : 0}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <button className="btn custom-yellow-btn w-100" onClick={handleAddCash}>CONTINUE ₹{selectedAmount.productPrice ? Intl.NumberFormat("en-IN").format(selectedAmount.productPrice) : 0}</button>
                    </div>
                </div>
                {/* {loading === "addCash" ? (
                    <div className="mb-2">
                        <Loader />
                    </div>
                ) : (
                    <></>
                )} */}
                {/* <>
                    <div className="" style={{ height: "calc(100vh - 538px)", overflowY: "auto" }}>
                        {loading === "preData" ? (
                            <Loader />
                        ) : (
                            transactionHistoryList &&
                            (transactionHistoryList.length > 0 ? (
                                transactionHistoryList.map((transaction, i) => (
                                    <div
                                        onClick={() => handlePendingPaymentModal(transaction)}
                                        className={`rounded tail-bg px-3 py-2 ${transaction.paymentStatus === "Completed" ? "completed" : ""}`}
                                        key={i}
                                    >
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="text-white font-size-12">
                                                <span>{staticConstants.TXN} # </span>
                                                <span className="text-yellow ml-2">{transaction.paymentTransactionRefId}</span>
                                            </div>
                                            <div className="text-white font-size-12">
                                                <span
                                                    className={`${
                                                        transaction.paymentStatus === "Completed"
                                                            ? "text-green"
                                                            : transaction.paymentStatus === "Failed" || transaction.paymentStatus === "Refunded"
                                                            ? "text-danger"
                                                            : "text-warning"
                                                    } ml-2`}
                                                >
                                                    {transaction.paymentStatus}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="text-white font-size-12">
                                                {transaction.paymentStatus === "Completed" && (
                                                    <>
                                                        <span>RRN No. # </span>
                                                        <span className="text-yellow ml-2">{transaction.paymentGatewayRefId}</span>
                                                    </>
                                                )}
                                            </div>
                                            <div className="text-white font-size-12 Maison-font">
                                                <span
                                                    className={`${
                                                        transaction.paymentStatus === "Completed"
                                                            ? "text-green"
                                                            : transaction.paymentStatus === "Failed" || transaction.paymentStatus === "Refunded"
                                                            ? "text-danger"
                                                            : "text-warning"
                                                    } ml-2`}
                                                >
                                                    {convertAmount(transaction.amount)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="text-white font-size-12">
                                                <span className="text-yellow">{transaction.paymentGateway}</span>
                                            </div>
                                            <div className="text-white font-size-12 Maison-font">
                                                <span className="text-yellow">
                                                    <i className="fa fa-calendar text-white mx-2" aria-hidden="true"></i>
                                                    {DateUtilities.formattedDate(transaction.createdAtTimeStamp, "MMM dd, uuuu, hh:mm aa")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="my-3 text-white text-center">No Data Available!</div>
                            ))
                        )}
                        {loading === "moreData" ? (
                            <Loader />
                        ) : (
                            historyListDetails &&
                            historyListDetails.totalItems > transactionHistoryList.length && (
                                <div className="pt-3 customised-btn-purple text-center">
                                    <button className="btn text-white" onClick={() => getList(true)}>
                                        Load More
                                    </button>
                                </div>
                            )
                        )}
                    </div>
                </> */}
            </div>
            <ModalBox show={showAddCashModal} onRequestClose={() => setShowAddCashModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-content bg-yellowGradient border-none">
                    <div className="modal-body pb-0 pt-2">
                        <div className="mt-3 d-flex justify-content-between align-items-center">
                            <span onClick={handleDownload} download="SpinIt-QrCode.jpg" className="d-block">
                                <img src="assets/img/download.png" className="img-fluid" style={{ maxWidth: "16px" }} />
                            </span>
                            {/* <a href={`data:image/png;base64,${payResponse.qrData}`} download="SpinIt-QrCode.jpg">
                                <img src="assets/img/download.png" className="img-fluid" style={{ maxWidth: "16px" }} />
                            </a> */}
                            <div className="text-yellow">PAY NOW</div>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setShowAddCashModal(false);
                                    getList();
                                    setAmount("");
                                }}
                            >
                                <span className="cross" aria-hidden="true">
                                    ×
                                </span>
                            </button>
                        </div>
                        <div className="px-4 text-center mt-4">
                            <img src={`data:image/png;base64,${payResponse.qrData}`} alt="" />
                            <h5 className="text-white my-4">{getCountdown()}</h5>
                            <div
                                className="pb-5 text-center custom-green-btn px-5"
                                onClick={() => {
                                    setAmount("");
                                    getList();
                                }}
                            >
                                <button onClick={handlePayNow} target="_blank" className="btn padding-custom-green w-100">
                                    Pay Now
                                </button>
                                {/* <a href={payResponse?.intentUrl} target="_blank" className="btn padding-custom-green w-100">
                                    Pay Now
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={showPaymentStatusModal} toggleModel={handleShowPaymentStatusModal} modalClass={"sliderBlockModal"}>
                <div className="modal-content bg-yellowGradient border-none">
                    <div className="modal-body pb-0 py-2">
                        <div className="text-right py-2">
                            <span onClick={handleShowPaymentStatusModal}>
                                <img src="assets/img/modal-close.png" alt="" style={{ width: "15px" }} />
                            </span>
                        </div>
                        <div className="py-4">
                            <div className="text-center">
                                {paymentResponse?.status === "Completed" ? (
                                    <img src="assets/img/check.svg" alt="" style={{ width: "44px" }} />
                                ) : paymentResponse?.status === "Failed" ? (
                                    <img src="assets/img/cancel.svg" alt="" style={{ width: "44px" }} />
                                ) : (
                                    <img src="assets/img/Union.png" alt="Low Balance" style={{ height: "44px" }} />
                                )}
                                <div
                                    style={{ color: "#FFCD50" }}
                                    className={`font-weight-bold font-size-18 ${paymentResponse?.status === "Hold" ? "" : paymentResponse?.status === "Failed" ? "text-danger" : "text-success"}`}
                                >
                                    {paymentResponse?.message}
                                </div>
                            </div>
                            <div className="py-5 text-center custom-green-btn px-5" onClick={handleShowPaymentStatusModal}>
                                <span className="btn padding-custom-green w-100 py-2">OK</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="line-custom"></div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            {showPendingPaymentModal && (
                <ModalBox show={showPendingPaymentModal} onRequestClose={() => setShowPendingPaymentModal(false)} modalClass={"sliderBlockModal"}>
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body pt-2">
                            <div className=" modal-body-header custom-border-light">
                                <button type="button" className="btn-close" onClick={() => setShowPendingPaymentModal(false)}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/modal-close.png" alt="" style={{ width: "15px" }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                    Pay Now
                                </h5>
                            </div>
                            <div className="">
                                <div className="">
                                    <div className="text-white font-size-14 py-3 custom-border-light d-flex align-items-center justify-content-between">
                                        <span className="font-size-14">Amount to be Added</span>
                                        <span className="text-white font-size-16 font-weight-600">{convertAmount(currentTransactionDetail.amount)}</span>
                                    </div>
                                    {loading === "UPIUrl" ? (
                                        <Loader />
                                    ) : UPIUrl ? (
                                        <div className="d-flex justify-content-between align-items-center text-white mt-3 ">
                                            <div className="text-center" onClick={() => payByUpiOptions("phonepe")}>
                                                <img src="/assets/img/payment/phone-pay.png" style={{ height: "50px", width: "50px" }} alt="" />
                                                <span className="d-block mt-2 font-size-12">PhonePe</span>
                                            </div>
                                            <div className="text-center" onClick={() => payByUpiOptions("paytmmp")}>
                                                <img src="/assets/img/payment/paytm.png" style={{ height: "50px", width: "50px" }} alt="" />
                                                <span className="d-block mt-2 font-size-12">PayTM</span>
                                            </div>
                                            <div className="text-center" onClick={() => payByUpiOptions("tez")}>
                                                <img src="/assets/img/payment/google-pay.png" style={{ height: "50px", width: "50px" }} alt="" />
                                                <span className="d-block mt-2 font-size-12">GooglePay</span>
                                            </div>
                                            <div className="text-center" onClick={handlePayByOther}>
                                                <img src="/assets/img/payment/other.png" style={{ height: "50px", width: "50px" }} alt="" />
                                                <span className="d-block mt-2 font-size-12">Other</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center my-4">
                                                <div className="text-center custom-green-btn px-2">
                                                    <button onClick={handlePayByUPI} target="_blank" className="btn padding-custom-green w-100">
                                                        Pay By UPI
                                                    </button>
                                                </div>
                                                {/* 
                                                <div className="text-center custom-green-btn px-2">
                                                    <button onClick={handlePayByCard} target="_blank" className="btn padding-custom-green w-100">
                                                        Pay By Card
                                                    </button>
                                                </div>
                                                */}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )}
            {showWebModal && (
                <ModalBox show={showWebModal} toggleModel={() => setShowWebModal(false)} modalClass={"sliderBlockModal fullScreenModal"}>
                    <div className="modal-content bg-yellowGradient border-none">
                        <div className="modal-body p-0">
                            <button type="button" className="btn-close" onClick={() => setShowWebModal(false)}>
                                <span className="cursor-pointer">
                                    <img src="assets/img/modal-close.png" alt="" style={{ width: "20px" }} />
                                </span>
                            </button>

                            <div>
                                <iframe src={currentTransactionDetail.callBackUrl} frameBorder="0" width={"100%"} style={{ height: "60vh", marginBottom: "-8px" }}></iframe>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )}
            {showWebModalForCardPayment && (
                <ModalBox show={showWebModalForCardPayment} toggleModel={() => setShowWebModalForCardPayment(false)} modalClass={"sliderBlockModal fullScreenModal h-100"}>
                    <div className="modal-content bg-yellowGradient border-none full-modal-content">
                        <div className="modal-body p-0">
                            <div className=" modal-body-header">
                                <button type="button" className="btn-close" onClick={() => setShowWebModalForCardPayment(false)}>
                                    <span className="cursor-pointer">
                                        <img src="assets/img/modal-close.png" alt="" style={{ width: "20px" }} />
                                    </span>
                                </button>
                                <h5 className="modal-title text-black font-size-18 font-weight-500 px-2 text-center" id="exampleModalLongTitle">
                                    Pay Now
                                </h5>
                            </div>

                            <div className="">
                                <iframe src={cardPaymentUrl} frameBorder="0" width={"100%"}></iframe>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            )}
        </Wrapper>
    );
};

export default AddCash;
