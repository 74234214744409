import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../App";
import FooterMenu from "./FooterMenu";
import Wrapper from "./Wrapper";
import { callGetApi } from "../api/ApiCaller";
import {
    GAME_PLAY_SERVICE_URL,
    Wallet_Balance_GET,
} from "../api/ApiConst";
import { toast } from "react-hot-toast";
import { NO_ERROR } from "../api/constants";
import { useNavigate } from "react-router-dom";
import { SET_ACTIVE_WALLET_TAB, SET_WALLET_DETAILS } from "../reducer/actions/utilityActions";
import { convertAmount } from "../utils/CurrencyUtils";
import { AuthTokenKey, getDataFromStore } from "utils/DataStore";
import { EarthIcon, PlusIcon, UserIcon } from "./common/SvgIcon";
import Loader from "./common/Loader";
import Slider from "react-slick";

const onlinePlayerDataObj = {
    classicPlayer: 0,
    timerPlayer: 0,
    turboPlayer: 0,
    totalPlayer: 0,
};

const Dashboard = () => {
    const { authState, utilityState, dispatchUtility } = useContext(GlobalContext);
    const [onlinePlayerData] = useState(onlinePlayerDataObj);
    const [show, setShow] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const handleNavigate = (navigateTo) => {
        dispatchUtility({ type: SET_ACTIVE_WALLET_TAB, payload: navigateTo });
        navigate("/wallet");
    };

    const getWalletDetails = () => {
        callGetApi(Wallet_Balance_GET, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                dispatchUtility({ type: SET_WALLET_DETAILS, payload: response.responsePacket });
            } else {
                toast.error(response.message, { id: "getWalletBalance" });
            }
        });
    };

    function receiveMessage(event) {
        if (event.data === "Gameplay") {
            setShow(false);
        } else if (event.data === "Dashboard") {
            setShow(true);
            getWalletDetails();
        }
    }

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);

        return () => {
            window.removeEventListener("message", receiveMessage, false);
        };
    }, []);

    const handleIframeLoad = () => {
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <Wrapper>
                <div className="d-block position-relative ">
                    <div className={`dashboard-header headerBg${show ? "" : " d-none"}`}>
                        <div className="container-fluid px-2">
                            <div className="d-flex justify-content-between">
                                <div className="col-4 cursor-pointer text-left p-0 " onClick={() => navigate("/profile")}>
                                    <div className="user-profile mt-2">
                                        <img className="rounded-circle" src={authState.userDetails?.profileImgUrl ? authState.userDetails?.profileImgUrl : "assets/img/avtaar-user.png"} />
                                    </div>
                                    <div className="user-content">
                                        <p className="text-white ">Profile</p>
                                    </div>
                                </div>

                                <div className="col-8 p-0 text-right pt-1">
                                    <div className="d-flex align-items-center justify-content-end">
                                        <span className="amountBtn cursor-pointer text-white mr-3" onClick={() => handleNavigate("Add Cash")}>
                                            <span className="rupeeIcon">
                                                {/* <RupeeIcon /> */}
                                                <EarthIcon />
                                            </span>
                                            <span className="px-2 ">{convertAmount(utilityState.walletDetails?.walletBalance)} </span>
                                            <span className="plus-icon">
                                                <PlusIcon />
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="onlinePlayer">
                            <span className="mr-2">
                                <UserIcon />
                            </span>
                            Total Online Players:
                            {onlinePlayerData.totalPlayer}
                        </span>
                    </div>
                    <div>
                        {
                            show && <div className="mt-3">
                                <Slider>
                                    <div>
                                        <img src="assets/img/banner_001.png" alt="homeSlider1" />
                                    </div>
                                </Slider>
                            </div>
                        }
                        <div className={`custom-main-content  position-relative${show ? "" : " dvh-100"}`}>
                            <iframe
                                src={GAME_PLAY_SERVICE_URL.replace("{accessToken}", getDataFromStore(AuthTokenKey).accessToken)}
                                width={"100%"}
                                height={"100%"}
                                className={`${isLoading ? 'd-none' : ''}`}
                                frameborder="0"
                                onLoad={handleIframeLoad}
                                onError={() => setIsLoading(false)}
                            />
                            {isLoading ? (
                                <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-transparent">
                                    <Loader />
                                </div>
                            ) : (
                                <></>
                            )}
                            {show && <FooterMenu />}
                        </div>
                    </div>
                </div>
            </Wrapper>
        </React.Fragment>
    );
};

export default Dashboard;
