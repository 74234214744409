import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../Wrapper";
import FooterMenu from "../FooterMenu";
import * as staticConstants from "../../constants/staticConstants";
import { GlobalContext } from "../../App";
import ModalBox from "../../components/common/Modal";
import Avatars from "./Avatars";
import { SET_PROFILE_DETAILS } from "../../reducer/actions/userActions";
import { NO_ERROR } from "../../api/constants";
import { Update_Avatar_Image_POST, Update_Nick_Name_POST } from "../../api/ApiConst";
import { callPostApi } from "../../api/ApiCaller";
import { toast } from "react-hot-toast";
import Kyc from "./kyc/Kyc";
import AvatarCard from "./AvatarCard";
import { useNavigate } from "react-router-dom";
import { SET_ACTIVE_WALLET_TAB, SHOW_SIDE_MENU } from "../../reducer/actions/utilityActions";
import { convertAmount } from "../../utils/CurrencyUtils";
import CustomHeader from "components/common/CustomHeader";

const Account = () => {
    const { authState, utilityState, dispatchAuth, dispatchUtility } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [showEditAvatarModal, setShowEditAvatarModal] = useState(false);
    const [enableEditing, setEnableEditing] = useState(false);
    const [userDetails, setUserDetails] = useState(authState.userDetails);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
        setUserDetails(authState.userDetails);
    }, [authState.userDetails]);

    const handleShowSideMenu = () => {
        dispatchUtility({ type: SHOW_SIDE_MENU });
    };

    const handleViewAllTransaction = () => {
        dispatchUtility({ type: SET_ACTIVE_WALLET_TAB, payload: "History" });
        navigate("/wallet");
    };

    const handleUpdateNickName = () => {
        if (authState.userDetails.nickName === userDetails.nickName) {
            setEnableEditing(false);
            return;
        } else if (userDetails.nickName === "") {
            toast("Nickname must contain at least one letter", { id: "nickName" });
            setUserDetails({ ...userDetails, nickName: authState.userDetails.nickName });
            return;
        }
        const rObj = {
            nickName: userDetails.nickName,
        };
        callPostApi(Update_Nick_Name_POST, rObj, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                dispatchAuth({
                    type: SET_PROFILE_DETAILS,
                    payload: userDetails,
                });
                setEnableEditing(false);
                toast.success(response.message, { id: "updateNickName" });
            } else {
                toast.error(response.message, { id: "updateNickName" });
            }
        });
    };

    const handleChangeAvatar = (imageUrl) => {
        setUserDetails({ ...userDetails, profileImgUrl: imageUrl });
    };

    const handleUpdateAvatar = (imageUrl) => {
        const rObj = {
            avatarUrl: imageUrl,
        };
        setLoading("profileImage");
        callPostApi(Update_Avatar_Image_POST, rObj, {}, (response) => {
            if (response.errorCode === NO_ERROR) {
                const userDetailsTemp = {
                    ...userDetails,
                    profileImgUrl: imageUrl,
                };
                setUserDetails(userDetailsTemp);
                dispatchAuth({ type: SET_PROFILE_DETAILS, payload: userDetailsTemp });
                setShowEditAvatarModal(false);
                setLoading(null);
                toast.success(response.message, { id: "updateAvatar" });
            } else {
                setLoading(null);
                toast.error(response.message, { id: "updateAvatar" });
            }
        });
    };

    return (
        <Wrapper>
            <div className="d-block">
                <CustomHeader title={"Edit Profile"} />
                <div className="custom-main-content">
                    <div className="container-fluid">
                        <div className="">
                            <AvatarCard
                                userDetails={userDetails}
                                toggleEditAvatarModal={() => setShowEditAvatarModal(true)}
                                enableEditing={enableEditing}
                                toggleEnableEditing={() => setEnableEditing(true)}
                                handleEditNickName={(nickName) => setUserDetails({ ...userDetails, nickName: nickName })}
                                handleUpdateNickName={handleUpdateNickName}
                            />
                            {/* <div className="p-2">
                                <div className="py-3 border-custom-bottom">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <img src="assets/img/wallet_icon.png" alt="wallet" style={{ width: 30 }} className=" mr-2" />
                                            <h6 className="mb-0 font-size-14 font-weight-600 text-white">{staticConstants.CASH_BALANCE}</h6>
                                        </div>
                                        <div>
                                            <p className="font-size-24 mb-0 Roboto-font text-green">₹ {convertAmount(utilityState.walletDetails?.walletBalance)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <img src="assets/img/refresh.png" alt="refresh" style={{ width: 25 }} className="mr-2" />
                                            <h6 className="mb-0 font-size-14 font-weight-600 text-white">{staticConstants.ALL_TRANSACTIONS}</h6>
                                        </div>
                                        <div className="custom-purple-btn" onClick={handleViewAllTransaction}>
                                            <button className="btn">{staticConstants.VIEW}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Kyc /> */}
                            {/* <KYCStatusCard /> */}
                        </div>
                    </div>
                </div>
            </div>
            {showEditAvatarModal && (
                <ModalBox show={showEditAvatarModal} toggleModel={() => setShowEditAvatarModal(false)} modalClass={"withdrawableListModal"}>
                    <Avatars
                        userDetails={userDetails}
                        toggleEditAvatarModal={() => setShowEditAvatarModal(false)}
                        handleChangeAvatar={handleChangeAvatar}
                        handleUpdateAvatar={handleUpdateAvatar}
                        loading={loading}
                    />
                </ModalBox>
            )}
        </Wrapper>
    );
};

export default Account;
