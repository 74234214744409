import { Component } from "react";
import { format } from "date-fns";

class DateUtilities extends Component {
    formattedDate = (timeStamp, resultFormat) => format(new Date(timeStamp), resultFormat);
}

const dateUtilities = new DateUtilities();

export default dateUtilities;
